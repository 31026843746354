













import { Component, Vue, Prop } from 'vue-property-decorator';
import AuthModule from '@/store/modules/AuthModule';

@Component
export default class UpgradeNavItem extends Vue {
  get canUpgradePlan() {
    return !AuthModule.profile!.HasActiveSubscription;
  }
}
