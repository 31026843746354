


































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IExportProcessingSummary } from '@/store/types/IExportProcessingSummary';

@Component
export default class ExportNotification extends Vue {
  @Prop() exp!: IExportProcessingSummary;
  @Prop() title!: string;
  @Prop() icon!: string;
  @Prop() spinicon!: boolean;
  @Prop() timecreated!: string;
  @Prop() dismissable!: boolean;
  created() {
    //every minute force update so that the time is correct
    const ONE_MINUTE = 60000;
    setInterval(() => this.$forceUpdate(), ONE_MINUTE);
  }

  @Emit()
  dismiss() {
    // does not need a return value
  }
}
