import Vue from 'vue';
import Router from 'vue-router';
import AuthModule from '@/store/modules/AuthModule';
import ImportUiStateModule from '@/store/modules/ImportUiStateModule';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import BillingModule from '@/store/modules/BillingModule';
import UserAccountModule from './store/modules/UserAccountModule';
import LayoutModule from './store/modules/LayoutModule';
import * as signalrService from '@/services/signalrservice';
import UserSettingsModule from './store/modules/UserSettingsModule';
//pages
const DashboardPage = () =>
  import('@/pages/dashboard/DashboardPage.vue');
const ExplorerPage = () =>
  import('@/pages/explorer/ExplorerPage.vue');
const ExportPage = () => import('@/pages/export/ExportPage.vue');
const ExportOptionsPage = () =>
  import('@/pages/export/ExportOptionsPage.vue');
const ExportCompletePage = () =>
  import('@/pages/export/ExportCompletePage.vue');
const ExportFailedPage = () =>
  import('@/pages/export/ExportFailedPage.vue');
const ExportProcessingPage = () =>
  import('@/pages/export/ExportProcessingPage.vue');
const ImportPage = () => import('@/pages/import/ImportPage.vue');
const ImportFileUploadPage = () =>
  import('@/pages/import/ImportFileUploadPage.vue');
const ImportChoicesPage = () =>
  import('@/pages/import/ImportChoicesPage.vue');
const SandboxPage = () => import('@/pages/sandbox/SandboxPage.vue');
const ImportProcessingPage = () =>
  import('@/pages/import/ImportProcessingPage.vue');
const ImportCompletePage = () =>
  import('@/pages/import/ImportCompletePage.vue');
const ImportFailedPage = () =>
  import('@/pages/import/ImportFailedPage.vue');
const ImportChromeExtensionPage = () =>
  import('@/pages/import/ImportChromeExtensionPage.vue');
const NotFoundPage = () => import('@/pages/NotFoundPage.vue');
const OfflinePage = () => import('@/pages/OfflinePage.vue');
const DiagnosticsPage = () =>
  import('@/pages/diagnostics/DiagnosticsPage.vue');
const SettingsPage = () =>
  import('@/pages/settings/SettingsPage.vue');
const SettingsGridPage = () =>
  import('@/pages/settings/SettingsGridPage.vue');
const AccountSettingsPage = () =>
  import('@/pages/settings/AccountSettingsPage.vue');
const AmazonSettingsPage = () =>
  import('@/pages/settings/AmazonSettingsPage.vue');
const NotelinkingSettingsPage = () =>
  import('@/pages/settings/NotelinkingsettingsPage.vue');
const IntegrationsPage = () =>
  import('@/pages/settings/IntegrationsPage.vue');
const BillingPage = () => import('@/pages/billing/BillingPage.vue');
const ConnectBrowserExtension = () =>
  import('@/pages/browserextension/ConnectBrowserExtension.vue');
const LoginPage = () => import('@/pages/account/LoginPage.vue');
const ForgotPasswordPage = () =>
  import('@/pages/account/ForgotPasswordPage.vue');
const ResetPasswordPage = () =>
  import('@/pages/account/ResetPasswordPage.vue');
const ChangePasswordPage = () =>
  import('@/pages/account/ChangePasswordPage.vue');
const SignupPage = () => import('@/pages/account/SignupPage.vue');
const GoodbyePage = () => import('@/pages/account/GoodbyePage.vue');

const TermsAndConditions = () =>
  import('@/pages/account/TermsAndConditions.vue');

const DeleteAccountPage = () =>
  import('@/pages/account/DeleteAccountPage.vue');
const ChangeUsernamePage = () =>
  import('@/pages/account/ChangeUsernamePage.vue');
const ChangeEmailPage = () =>
  import('@/pages/account/ChangeEmailPage.vue');

Vue.use(Router);

const exportRoutes = [
  {
    path: 'wizard',
    component: ExportOptionsPage,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to: any, from: any, next: any) => {
      ExportUiStateModule.selectIntegration(0);
      next();
    },
  },
  {
    path: ':exporthistoryid/processing',
    component: ExportProcessingPage,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to: any, from: any, next: any) => {
      next();
      // we cant use the code below as it takes signalR a while to produce the first processingExport definition
      // if (!ExportUiStateModule.processingExportById(Number(to.params.exporthistoryid))) {
      //   next('/explorer');
      // } else {
      //   next();
      // }
    },
  },
  {
    path: ':exporthistoryid/complete',
    component: ExportCompletePage,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        !ExportUiStateModule.completeExportById(
          Number(to.params.exporthistoryid),
        )
      ) {
        next('/explorer');
      } else {
        next();
      }
    },
  },
  {
    path: ':exporthistoryid/failed',
    component: ExportFailedPage,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        !ExportUiStateModule.completeExportById(
          Number(to.params.exporthistoryid),
        )
      ) {
        next('/explorer');
      } else {
        next();
      }
    },
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/alert',
    //   name: 'alert',
    //   meta: {
    //     title: 'Alert',
    //     requiresAuth: false,
    //   },
    //   beforeEnter: (to: any, from: any, next: any) => {
    //     alert('test');
    //     next(from);
    //   },
    // },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: {
        title: 'Clippings.io Login',
        requiresAuth: false,
      },
      beforeEnter: (to: any, from: any, next: any) => {
        AuthModule.resetLoginPage();
        if (AuthModule.isAuthenticated) {
          next('/');
        } else {
          next();
        }
      },
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: ForgotPasswordPage,
      meta: {
        title: 'Clippings.io Forgot Password',
        requiresAuth: false,
      },
      beforeEnter: (to: any, from: any, next: any) => {
        UserAccountModule.resetForgotPasswordPage();
        if (AuthModule.isAuthenticated) {
          next('/');
        } else {
          next();
        }
      },
    },

    {
      path: '/resetpassword/:resetpasswordcode',
      name: '/resetpassword',
      component: ResetPasswordPage,
      meta: {
        title: 'Clippings.io Reset Password',
        requiresAuth: false,
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignupPage,
      meta: {
        title:
          'Signup to Clippings.io the easy way to manage your Kindle highlights',
        requiresAuth: false,
      },
      beforeEnter: (to: any, from: any, next: any) => {
        UserAccountModule.resetSignupPage();
        if (AuthModule.isAuthenticated) {
          next('/');
        } else {
          next();
        }
      },
    },
    {
      path: '/goodbye',
      name: 'goodbye',
      component: GoodbyePage,
      meta: {
        title: 'Clippings.io Goodbye',
        requiresAuth: false,
      },
    },
    {
      path: '/terms',
      name: 'terms',
      component: TermsAndConditions,
      meta: {
        title: 'Clippings.io Terms and Conditions',
        requiresAuth: false,
      },
    },
    {
      path: '/',
      name: 'dashboard',
      component: DashboardPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/sandbox/',
      name: 'sandbox',
      component: SandboxPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/connectbrowserextension/extensionid/:extensionid',
      name: 'connectbrowserextension',
      component: ConnectBrowserExtension,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/settings',
      component: SettingsPage,
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'settingshome',
          component: SettingsGridPage,
        },
        {
          path: 'account/',
          name: 'account',
          component: AccountSettingsPage,
        },
        {
          path: 'account/delete',
          name: 'deleteaccount',
          component: DeleteAccountPage,
          beforeEnter: (to: any, from: any, next: any) => {
            UserAccountModule.resetDeleteAccountPage();
            next();
          },
        },
        {
          path: 'account/changeemail',
          name: 'changeemail',
          component: ChangeEmailPage,
          beforeEnter: (to: any, from: any, next: any) => {
            UserAccountModule.resetChangeEmailPage();
            next();
          },
        },
        {
          path: 'account/changeusername',
          name: 'changeusername',
          component: ChangeUsernamePage,
          beforeEnter: (to: any, from: any, next: any) => {
            UserAccountModule.resetChangeUsernamePage();
            next();
          },
        },
        {
          path: 'account/changepassword',
          name: 'changepassword',
          component: ChangePasswordPage,
          beforeEnter: (to: any, from: any, next: any) => {
            UserAccountModule.resetChangePasswordPage();
            next();
          },
        },

        {
          path: 'integrations',
          component: IntegrationsPage,
          meta: { layoutNoSidebar: true },
        },
        { path: 'notelinking', component: NotelinkingSettingsPage },
        {
          path: 'integrations/amazon',
          component: AmazonSettingsPage,
        },
        {
          path: 'integrations/connected/:integrationid',
          component: IntegrationsPage,
          beforeEnter: async (to: any, from: any, next: any) => {
            console.log(to.params.integrationid);
            UserSettingsModule.integrationConnected(
              to.params.integrationid,
            );

            const authenticateIntegrationCallingUrl = localStorage.getItem(
              'authenticateIntegrationCallingUrl',
            ) as string;
            //console.log(authenticateIntegrationCallingUrl);

            //we always need to redirect if the intial call came from the exports page
            if (
              authenticateIntegrationCallingUrl.includes('/wizard')
            ) {
              next(authenticateIntegrationCallingUrl);
            } else {
              //naviagate back to integrations to remove the connected params from the url
              next('/settings/integrations');
            }
          },
        },
        {
          path: 'billing',
          component: BillingPage,
          beforeEnter: async (to: any, from: any, next: any) => {
            await BillingModule.loadBillingInfo(to.query.session_id);
            next();
          },
          meta: {
            layoutNoSidebar: true,
          },
        },
      ],
    },
    {
      path: '/explorer',
      component: ExplorerPage,
      redirect: '/explorer/all',
      meta: {
        requiresAuth: true,
      },
    },
    //export wizard paths
    {
      path: '/explorer/:folder/:selecteditemid?/export',
      component: ExportPage,
      meta: {
        requiresAuth: true,
      },
      children: exportRoutes,
      beforeEnter: (to: any, from: any, next: any) => {
        if (ExplorerDataModule.Imports.length === 0) {
          next('/');
        } else {
          next();
        }
      },
    },
    //notice the question mark below
    //means the parameter is optional and therefore match
    // routes /explorer/all/export and /explorer/book/1234/export
    {
      path: '/explorer/:folder/:selecteditemid?',
      name: 'folderwithitem', //need a name for this route for use with search, see appheader
      component: ExplorerPage,
      meta: {
        requiresAuth: true,
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (ExplorerDataModule.Imports.length === 0) {
          next('/');
        } else {
          next();
        }
      },
    },
    {
      path: '/import',
      component: ImportPage,
      meta: { requiresAuth: true, layoutNoSidebar: true },
      children: [
        {
          path: '',
          name: 'importchoices',
          component: ImportChoicesPage,
          meta: { requiresAuth: true, layoutNoSidebar: true },
        },
        {
          path: 'chrome',
          name: 'chrome',
          component: ImportChromeExtensionPage,
        },
        {
          path: 'chrome/processing/:uploadGuid',
          component: ImportProcessingPage,
        },
        {
          path: 'file',
          name: 'file',
          component: ImportFileUploadPage,
        },
        {
          path: 'file/processing/:uploadGuid',
          component: ImportProcessingPage,
        },
        {
          path: 'complete',
          component: ImportCompletePage,
          beforeEnter: (to: any, from: any, next: any) => {
            if (!ImportUiStateModule.importComplete) {
              next('/import');
            } else {
              next();
            }
          },
        },
        {
          path: 'failed',
          component: ImportFailedPage,
          beforeEnter: (to: any, from: any, next: any) => {
            if (!ImportUiStateModule.importComplete) {
              next('/import');
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: '/diagnostics',
      component: DiagnosticsPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/offline',
      component: OfflinePage,
      meta: {
        title: 'Clippings.io Offline',
        requiresAuth: false,
      },
    },
    {
      path: '*',
      component: NotFoundPage,
      meta: {
        title: 'Clippings.io Not found',
        requiresAuth: false,
      },
    },
  ],
});

// https://github.com/joaojosefilho/vuejsOidcClient/blob/master/src/index.js
router.beforeEach(async (to, from, next) => {
  //set document title
  document.title =
    to.meta.title ||
    'Export your Kindle Highlights with Clippings.io';

  //Take the app offline except for https://clippingsiospa.netlify.app/
  if (
    process.env.VUE_APP_OFFLINE === 'true' &&
    to.path !== '/offline' &&
    (location.hostname === 'localhost' ||
      location.hostname === 'my.clippings.io')
  ) {
    console.log('Taking offline');
    next('/offline');
    return;
  }

  const requiresAuth = to.matched.some(
    (route) => route.meta.requiresAuth,
  );

  const layoutNoSidebar = to.matched.some(
    (route) => route.meta.layoutNoSidebar,
  );

  if (layoutNoSidebar || LayoutModule.isMobile) {
    LayoutModule.hideLeftSidebar();
  }

  if (requiresAuth) {
    AuthModule.load();
    if (!AuthModule.isAuthenticated) {
      console.log('logging in');
      next('/login');
    } else {
      // if (to.path === '/alert') {
      //   alert('test');
      //   next(false);
      // }

      if (!ExplorerDataModule.isInitialised) {
        await ExplorerDataModule.loadExplorerDataAndSettingsData();
        signalrService.startConnectionIfNotStarted(); //don't await, takes too long
        next();
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
