var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-nav-item',{staticClass:"nav-item-header"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_vm._v(_vm._s(_vm.$t('TL_SHARED_IMPORTS').toUpperCase()))]),_vm._v(" "),_c('b-col',{staticClass:"text-right"},[_c('showhidetoggle',{attrs:{"expanded":_vm.menuExpanded},on:{"update:expanded":function($event){_vm.menuExpanded=$event}}})],1)],1)],1),_vm._v(" "),(_vm.imports.length === 0)?_c('b-nav-item',[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"info-circle","fixed-width":""}}),_vm._v(_vm._s(_vm.$t('TL_SHARED_IMPORTS_YOU_HAVE_NONE'))+"\n  ")],1):_vm._e(),_vm._v(" "),(_vm.menuExpanded)?_c('div',[_vm._l((_vm.pagedItems),function(upload){return _c('b-nav-item',{key:upload.FileUploadId,class:{
        active:
          _vm.$route.path.includes('/explorer/imports') &&
          _vm.$route.params.selecteditemid ===
            String(upload.FileUploadId),
      },attrs:{"to":{ path: ("/explorer/imports/" + (upload.FileUploadId)) }},on:{"mouseover":function($event){return _vm.mouseEnter(upload.FileUploadId)},"mouseleave":function($event){return _vm.mouseLeave()}}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"11"}},[_c('span',[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"cloud-upload-alt","fixed-width":""}}),_vm._v("\n            "+_vm._s(_vm._f("moment")(upload.DateUploadedUTC,'lll'))+"\n          ")],1)]),_vm._v(" "),_c('b-col',{staticClass:"text-right",attrs:{"cols":"1"}},[(_vm.mouseOverFileUploadId == upload.FileUploadId)?_c('font-awesome-icon',{attrs:{"icon":"trash","fixed-width":""},on:{"click":function($event){return _vm.deleteUpload(upload.FileUploadId)}}}):_vm._e()],1)],1),_vm._v(" "),_c('b-row',{staticClass:"ml-4 my-2",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[(upload.FileUploadTypeId == 2)?_c('font-awesome-icon',{attrs:{"icon":['fab', 'amazon'],"fixed-width":""}}):_vm._e(),_vm._v(" "),(upload.FileUploadTypeId == 1)?_c('font-awesome-icon',{attrs:{"icon":['far', 'file-alt'],"fixed-width":""}}):_vm._e(),_vm._v("\n          "+_vm._s(upload.OriginalFileName)+"\n\n          "),_vm._v(" "),(
              _vm.mouseOverDownloadFileUploadId == upload.FileUploadId
            )?_c('font-awesome-icon',{staticClass:"ml-2",attrs:{"icon":['fas', 'download']},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(upload)},"mouseout":function($event){return _vm.mouseLeaveDownload()},"mouseover":function($event){return _vm.mouseEnterDownload(upload.FileUploadId)}}}):_c('font-awesome-icon',{staticClass:"ml-2",attrs:{"icon":['far', 'download']},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(upload)},"mouseover":function($event){return _vm.mouseEnterDownload(upload.FileUploadId)},"mouseout":function($event){return _vm.mouseLeaveDownload()}}})],1)],1)],1)}),_vm._v(" "),_c('leftsibebarshowall',{attrs:{"showall":_vm.showAll,"pagesize":_vm.pageSize,"itemcount":_vm.imports.length,"title":_vm.$t('TL_SHARED_IMPORTS').toLowerCase()},on:{"update:showall":function($event){_vm.showAll=$event}}})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }