










import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import AuthModule from '@/store/modules/AuthModule';

@Component
export default class Integrationsselector extends Vue {
  get isLoaded() {
    return AuthModule.isLoaded;
  }
  get userInitial() {
    return AuthModule.profile!.UserName.charAt(0).toUpperCase();
  }
}
