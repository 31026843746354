import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import * as api from '@/store/api';
import AuthModule from '@/store/modules/AuthModule';
import { ISignup } from '../types/ISignup';
import router from '@/router';
import { ISignupResult } from '../types/ISignupResult';
import { ISendForgotPasswordEmailResult } from '../types/IForgotPasswordResult';
import { IVerifyPasswordResultCodeResult } from '../types/IVerifyPasswordResultCodeResult';
import { IUpdatePasswordWithCodeResult } from '../types/IUpdatePasswordWithCodeResult';
import { IResetPasswordWithCode } from '../types/IResetPasswordWithCode';
import { IChangeEmailResult } from '../types/IChangeEmailResult';
import { IUpdatePasswordResult } from '../types/IUpdatePasswordResult';
import { IChangeUsernameResult } from '../types/IChangeUsernameResult';
import { IDeleteAccountResult } from '../types/IDeleteAccountResult';

@Module({ dynamic: true, store, name: 'useraccount' })
class UserAccountModule extends VuexModule {
  signupProcessing = false;
  signupError = '';
  signupSucceeded = false;
  sendForgotPasswordEmailProcessing = false;
  sendForgotPasswordEmailSucceeded = false;
  sendForgotPasswordEmailError = '';
  //verify password reset code - used to reset password
  verifyPasswordResetCodeProcessing = false;
  verifyPasswordResetCodeError = '';
  verifyPasswordResetCodeSucceeded = false;
  //update password with code - used to reset password
  updatePasswordWithCodeProcessing = false;
  updatePasswordWithCodeError = '';
  updatePasswordWithCodeEmail = '';
  updatePasswordWithCodeSucceeded = false;
  //update password - used when logged in
  updatePasswordProcessing = false;
  updatePasswordError = '';
  updatePasswordSucceeded = false;

  changeEmailProcessing = false;
  changeEmailSucceeded = false;
  changeEmailError = '';

  changeUsernameProcessing = false;
  changeUsernameSucceeded = false;
  changeUsernameError = '';

  deleteAccountProcessing = false;
  deleteAccountSucceeded = false;
  deleteAccountError = '';

  @Mutation
  clear() {
    this.signupProcessing = false;
    this.signupError = '';
    this.signupSucceeded = false;
    this.sendForgotPasswordEmailProcessing = false;
    this.sendForgotPasswordEmailSucceeded = false;
    this.sendForgotPasswordEmailError = '';
    //verify password reset code - used to reset password
    this.verifyPasswordResetCodeProcessing = false;
    this.verifyPasswordResetCodeError = '';
    this.verifyPasswordResetCodeSucceeded = false;
    //update password with code - used to reset password
    this.updatePasswordWithCodeProcessing = false;
    this.updatePasswordWithCodeError = '';
    this.updatePasswordWithCodeEmail = '';
    this.updatePasswordWithCodeSucceeded = false;
    //update password - used when logged in
    this.updatePasswordProcessing = false;
    this.updatePasswordError = '';
    this.updatePasswordSucceeded = false;

    this.changeEmailProcessing = false;
    this.changeEmailSucceeded = false;
    this.changeEmailError = '';

    this.changeUsernameProcessing = false;
    this.changeUsernameSucceeded = false;
    this.changeUsernameError = '';

    this.deleteAccountProcessing = false;
    this.deleteAccountSucceeded = false;
    this.deleteAccountError = '';
  }

  @Mutation
  resetDeleteAccountPage() {
    this.deleteAccountProcessing = false;
    this.deleteAccountSucceeded = false;
    this.deleteAccountError = '';
  }

  @Mutation
  setDeleteAccountProcessing() {
    this.deleteAccountProcessing = true;
    this.deleteAccountSucceeded = false;
    this.deleteAccountError = '';
  }

  @Mutation
  deleteAccountSuccess(result: IDeleteAccountResult) {
    this.deleteAccountProcessing = false;
    this.deleteAccountError = '';
    this.deleteAccountSucceeded = true;
  }

  @Mutation
  deleteAccountFailed(result: IDeleteAccountResult) {
    this.deleteAccountProcessing = false;
    this.deleteAccountError = result.errorMessage;
    this.deleteAccountSucceeded = false;
  }

  @Action
  async deleteAccount(reasonforleaving: string) {
    try {
      this.context.commit('setDeleteAccountProcessing');
      const result = await api.deleteAccount(reasonforleaving);
      this.context.commit('deleteAccountSuccess', result);
    } catch (error) {
      this.context.commit(
        'deleteAccountFailed',
        error as IDeleteAccountResult,
      );
    }
  }

  @Mutation
  resetChangeEmailPage() {
    this.changeEmailProcessing = false;
    this.changeEmailSucceeded = false;
    this.changeEmailError = '';
  }

  @Mutation
  setChangeEmailProcessing() {
    this.changeEmailProcessing = true;
    this.changeEmailSucceeded = false;
    this.changeEmailError = '';
  }

  @Mutation
  changeEmailSuccess(result: IChangeEmailResult) {
    this.changeEmailProcessing = false;
    this.changeEmailError = '';
    this.changeEmailSucceeded = true;
  }

  @Mutation
  changeEmailFailed(result: IChangeEmailResult) {
    this.changeEmailProcessing = false;
    this.changeEmailError = result.errorMessage;
    this.changeEmailSucceeded = false;
  }

  @Mutation
  resetChangeUsernamePage() {
    this.changeUsernameProcessing = false;
    this.changeUsernameSucceeded = false;
    this.changeUsernameError = '';
  }

  @Mutation
  setChangeUsernameProcessing() {
    this.changeUsernameProcessing = true;
    this.changeUsernameSucceeded = false;
    this.changeUsernameError = '';
  }

  @Mutation
  changeUsernameSuccess(result: IChangeUsernameResult) {
    this.changeUsernameProcessing = false;
    this.changeUsernameError = '';
    this.changeUsernameSucceeded = true;
  }

  @Mutation
  changeUsernameFailed(result: IChangeUsernameResult) {
    this.changeUsernameProcessing = false;
    this.changeUsernameError = result.errorMessage;
    this.changeUsernameSucceeded = false;
  }

  @Mutation
  setUpdatePasswordWithCodeProcessing() {
    this.updatePasswordWithCodeProcessing = true;
    this.updatePasswordWithCodeError = '';
    this.updatePasswordWithCodeSucceeded = false;
    this.updatePasswordWithCodeEmail = '';
  }

  @Mutation
  updatePasswordWithCodeSuccess(
    result: IUpdatePasswordWithCodeResult,
  ) {
    this.updatePasswordWithCodeProcessing = false;
    this.updatePasswordWithCodeError = '';
    this.updatePasswordWithCodeSucceeded = true;
    this.updatePasswordWithCodeEmail = result.email;
  }

  @Mutation
  updatePasswordWithCodeFailed(
    result: IUpdatePasswordWithCodeResult,
  ) {
    this.updatePasswordWithCodeProcessing = false;
    this.updatePasswordWithCodeError = result.errorMessage;
    this.updatePasswordWithCodeSucceeded = false;
    this.updatePasswordWithCodeEmail = '';
  }

  @Mutation
  resetChangePasswordPage() {
    this.updatePasswordProcessing = false;
    this.updatePasswordError = '';
    this.updatePasswordSucceeded = false;
  }

  @Mutation
  setUpdatePasswordProcessing() {
    this.updatePasswordProcessing = true;
    this.updatePasswordError = '';
    this.updatePasswordSucceeded = false;
  }

  @Mutation
  updatePasswordSuccess(result: IUpdatePasswordResult) {
    this.updatePasswordProcessing = false;
    this.updatePasswordError = '';
    this.updatePasswordSucceeded = true;
  }

  @Mutation
  updatePasswordFailed(result: IUpdatePasswordResult) {
    this.updatePasswordProcessing = false;
    this.updatePasswordError = result.errorMessage;
    this.updatePasswordSucceeded = false;
  }

  @Mutation
  setVerifyPasswordResetCodeProcessing() {
    this.verifyPasswordResetCodeProcessing = true;
    this.verifyPasswordResetCodeError = '';
    this.verifyPasswordResetCodeSucceeded = false;
  }

  @Mutation
  verifyPasswordResetCodeSuccess(
    result: ISendForgotPasswordEmailResult,
  ) {
    this.verifyPasswordResetCodeProcessing = false;
    this.verifyPasswordResetCodeError = '';
    this.verifyPasswordResetCodeSucceeded = true;
  }

  @Mutation
  verifyPasswordResetCodeFailed(
    result: IVerifyPasswordResultCodeResult,
  ) {
    this.verifyPasswordResetCodeProcessing = false;
    this.verifyPasswordResetCodeError = result.errorMessage;
    this.verifyPasswordResetCodeSucceeded = false;
  }

  @Mutation
  resetForgotPasswordPage() {
    this.sendForgotPasswordEmailProcessing = false;
    this.sendForgotPasswordEmailSucceeded = false;
    this.sendForgotPasswordEmailError = '';
  }

  @Mutation
  setSendForgotPasswordEmailProcessing() {
    this.sendForgotPasswordEmailProcessing = true;
    this.sendForgotPasswordEmailSucceeded = false;
    this.sendForgotPasswordEmailError = '';
  }

  @Mutation
  sendForgotPasswordEmailSuccess(
    result: IVerifyPasswordResultCodeResult,
  ) {
    this.sendForgotPasswordEmailProcessing = false;
    this.sendForgotPasswordEmailError = '';
    this.sendForgotPasswordEmailSucceeded = true;
  }

  @Mutation
  sendForgotPasswordEmailFailed(
    result: ISendForgotPasswordEmailResult,
  ) {
    this.sendForgotPasswordEmailProcessing = false;
    this.sendForgotPasswordEmailError = result.errorMessage;
    this.sendForgotPasswordEmailSucceeded = false;
  }

  @Mutation
  resetSignupPage() {
    this.signupProcessing = false;
    this.signupSucceeded = false;
    this.signupError = '';
  }

  @Mutation
  setSignupProcessing() {
    this.signupProcessing = true;
    this.signupSucceeded = false;
    this.signupError = '';
  }

  @Mutation
  signupSuccess(signup: ISignupResult) {
    this.signupProcessing = false;
    this.signupSucceeded = true;
    this.signupError = '';
  }

  @Mutation
  signupFailed(signup: ISignupResult) {
    this.signupSucceeded = false;
    this.signupProcessing = false;
    this.signupError = signup.errorMessage;
  }

  @Action
  async signUp(signup: ISignup) {
    try {
      this.context.commit('setSignupProcessing');
      const signUpresult = await api.signUp(signup);
      this.context.commit('signupSuccess', signUpresult);
      AuthModule.updateIntercom(signUpresult.profile);
      AuthModule.loginSuccess(signUpresult);
      router.push('/');
    } catch (error) {
      this.context.commit('signupFailed', error as ISignupResult);
    }
  }

  @Action
  async sendForgotPasswordEmail(usernameoremail: string) {
    try {
      this.context.commit('setSendForgotPasswordEmailProcessing');
      const forgotPasswordResult = await api.sendForgotPasswordEmail(
        usernameoremail,
      );
      this.context.commit(
        'sendForgotPasswordEmailSuccess',
        forgotPasswordResult,
      );
    } catch (error) {
      this.context.commit(
        'sendForgotPasswordEmailFailed',
        error as ISendForgotPasswordEmailResult,
      );
    }
  }

  @Action
  async verifyPasswordResetCode(code: string) {
    try {
      this.context.commit('setVerifyPasswordResetCodeProcessing');
      const verifyResetCodeResult = await api.verifyPasswordResetCode(
        code,
      );
      this.context.commit(
        'verifyPasswordResetCodeSuccess',
        verifyResetCodeResult,
      );
    } catch (error) {
      this.context.commit(
        'verifyPasswordResetCodeFailed',
        error as IVerifyPasswordResultCodeResult,
      );
    }
  }

  @Action
  async updatePasswordWithCode(
    passwordWithCode: IResetPasswordWithCode,
  ) {
    try {
      this.context.commit('setUpdatePasswordWithCodeProcessing');
      const updatePasswordWithCodeResult = await api.updatePasswordWithCode(
        passwordWithCode,
      );
      this.context.commit(
        'updatePasswordWithCodeSuccess',
        updatePasswordWithCodeResult,
      );
    } catch (error) {
      this.context.commit(
        'updatePasswordWithCodeFailed',
        error as IUpdatePasswordWithCodeResult,
      );
    }
  }

  @Action
  async updatePassword(newPassword: string) {
    try {
      this.context.commit('setUpdatePasswordProcessing');
      const updatePasswordResult = await api.updatePassword({
        newPassword,
      });
      this.context.commit(
        'updatePasswordSuccess',
        updatePasswordResult,
      );
    } catch (error) {
      this.context.commit(
        'updatePasswordFailed',
        error as IUpdatePasswordResult,
      );
    }
  }

  @Action
  async changeEmail(newemail: string) {
    try {
      this.context.commit('setChangeEmailProcessing');
      const changeEmailResult = await api.changeEmail(newemail);
      this.context.commit('changeEmailSuccess', changeEmailResult);
      AuthModule.refreshUser();
    } catch (error) {
      this.context.commit(
        'changeEmailFailed',
        error as IChangeEmailResult,
      );
    }
  }

  @Action
  async changePreferredLanguage(preferredLanguage: string) {
    try {
      // this.context.commit('setChangeEmailProcessing');
      const result = await api.changePreferredLanguage(
        preferredLanguage,
      );
      // this.context.commit('changeEmailSuccess', changeEmailResult);
      AuthModule.refreshUser();
    } catch (error) {
      // todo
      // this.context.commit(
      //   'changeEmailFailed',
      //   error as IChangeEmailResult,
      // );
    }
  }

  @Action
  async changeUserName(newusername: string) {
    try {
      this.context.commit('setChangeUsernameProcessing');
      const changeUsernameResult = await api.changeUsername(
        newusername,
      );
      this.context.commit(
        'changeUsernameSuccess',
        changeUsernameResult,
      );
      AuthModule.refreshUser();
    } catch (error) {
      this.context.commit(
        'changeUsernameFailed',
        error as IChangeEmailResult,
      );
    }
  }
}

export default getModule(UserAccountModule);
