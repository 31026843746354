




































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import AuthModule from '@/store/modules/AuthModule';
import logoloadingindicator from '@/components/logoloadingindicator.vue';
import appheader from '@/components/navbars/AppHeader.vue';
import notificationssidebar from '@/components/notificationssidebar/NotificationsSidebar.vue';
import leftnavbar from '@/components/navbars/LeftNavbar.vue';
import leftsidebar from '@/pages/explorer/components/leftsidebar/LeftSidebar.vue';
import baselayout from '@/components/layouts/BaseLayout.vue';
import unauthlayout from '@/components/layouts/UnAuthLayout.vue';
import LayoutModule from '@/store/modules/LayoutModule';
import logo from '@/components/logos/Logo.vue';
import * as Sentry from '@sentry/vue';
Sentry.init({
  Vue: Vue,
  dsn:
    'https://e8e788299c9843768e4d54c4a25c1a29@o447534.ingest.sentry.io/5623725',
  environment: process.env.NODE_ENV,
  logErrors: process.env.NODE_ENV === 'development' ? true : false, // only log errors to the console in development
});

@Component({
  components: {
    appheader,
    notificationssidebar,
    logoloadingindicator,
    leftnavbar,
    leftsidebar,
    baselayout,
    unauthlayout,
    logo,
  },
})
export default class App extends Vue {
  created() {
    window.addEventListener('resize', this.handleWindowResize);
    // to view intercom for vistors whilst debugging you need to use
    //http://127.0.0.1:8080/login because you can't whitelist localhost in the trusted domains
    // intercom.startIntercomForVisitors();
  }

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize(ev: any) {
    LayoutModule.setWindowWidth(window.innerWidth);
  }

  get isMobile() {
    return LayoutModule.isMobile;
  }

  get screenSize() {
    return LayoutModule.screenSize;
  }

  get isLoading() {
    return !ExplorerDataModule.isInitialised;
  }

  get showLoadingIndicator() {
    return (
      AuthModule.isAuthenticated &&
      this.$route.meta.requiresAuth &&
      !ExplorerDataModule.isInitialised
    );
  }

  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }

  get isInitialised() {
    //return true;
    return ExplorerDataModule.isInitialised;
  }
}
