












































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Logoloadingindicator extends Vue {
  // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome
  get isIE() {
    return /Trident/.test(navigator.userAgent);
  }
}
