

































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import { IBooksInCollections } from '@/store/types/IBooksInCollections';
import { IBook } from '@/store/types/IBook';
import leftsidebarbookcollectionbooks from '@/pages/explorer/components/leftsidebar/LeftSidebarBookCollectionBooks.vue';
import editcollectionmodal from '@/components/modals/EditCollectionModal.vue';

@Component({
  components: {
    leftsidebarbookcollectionbooks,
    editcollectionmodal,
  },
})
export default class Leftsidebarbookcollection extends Vue {
  @Prop() bookCollection!: IBooksInCollections;
  @Prop() isonlyonecollection!: boolean;
  menuExpanded = false;
  isHoveredBookCollection = false;
  collectionsModalVisible = false;

  get bookcollectionicon() {
    return this.menuExpanded ? 'folder-open' : 'folder';
  }

  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }

  get bookfilter() {
    return ExplorerUiStateModule.bookFilter;
  }

  mounted() {
    this.menuExpanded = this.shouldMenuBeExpanded();
  }

  displayBookCollection() {
    // Don't display the book collection if:
    // The book filter is applied and this collection does not contain any books matching the filter
    // It's the uncategorised collection and it has no books
    if (
      this.bookfilter.length > 0 &&
      this.bookCollection.Books.filter((book) =>
        book.BookTitle.toLowerCase().includes(
          this.bookfilter.toLowerCase(),
        ),
      ).length === 0
    ) {
      return false;
    }
    return !(
      this.bookCollection.BookCollectionID === -1 &&
      this.bookCollection.Books.length === 0
    );
  }

  bookFilterApplied() {
    return this.bookfilter.length > 0;
  }

  filteredBooks(): IBook[] {
    if (this.bookfilter.length === 0) {
      return this.bookCollection.Books;
    } else {
      return this.bookCollection.Books.filter((book) =>
        book.BookTitle.toLowerCase().includes(
          this.bookfilter.toLowerCase(),
        ),
      );
    }
  }

  shouldMenuBeExpanded() {
    // Book Collection Menu should be expanded if:
    // There is only one book collection
    // A book search filter is applied
    // It's books contains the active book
    // Book Collection Menu should NOT be expanded if:
    // If a book folder is not selected

    if (this.isonlyonecollection) {
      return true;
    }

    if (this.bookfilter.length > 0) {
      return true;
    }

    if (this.selectedfolder.FolderName !== 'books') {
      return false;
    }

    if (this.bookCollection.Books != null) {
      return (
        this.bookCollection.Books.filter(
          (book) =>
            book.BookId === Number(this.selectedfolder.SelectedId),
        ).length > 0
      );
    } else {
      return false;
    }
  }

  toggleMenuExpanded() {
    this.menuExpanded = !this.menuExpanded;
  }

  handleHoverBookCollection(hovered: boolean) {
    this.isHoveredBookCollection = hovered;
  }

  editBookCollection() {
    this.collectionsModalVisible = true;
  }
}
