import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import * as api from '@/store/api';
import { IBillingOverview } from '../types/IBillingOverview';
import AuthModule from '@/store/modules/AuthModule';
import Vue from 'vue';
import { IStripePlan } from '../types/IStripePlan';
import i18n from '@/i18n';

@Module({ dynamic: true, store, name: 'billinginfo' })
class BillingInfoModule extends VuexModule {
  isLoading = true;
  isInitialised = false;
  hasSubscription = false;
  cancelAtPeriodEnd = false;
  stripePlans: IStripePlan[] = [];

  @Mutation
  clear() {
    this.isLoading = true;
    this.isInitialised = false;
    this.hasSubscription = false;
    this.cancelAtPeriodEnd = false;
    this.stripePlans = [];
  }

  @Mutation
  setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  @Mutation
  billingInfoLoaded(response: IBillingOverview) {
    this.hasSubscription = response.hasSubscription;
    this.cancelAtPeriodEnd = response.cancelAtPeriodEnd;
    this.stripePlans = response.stripePlans;
    this.isInitialised = true;
  }

  @Action
  async loadBillingInfo(sessionId: string) {
    this.context.commit('setIsLoading', true);
    const response = await api.loadBillingInfo(sessionId);
    if (response) {
      this.context.commit('billingInfoLoaded', response);
      await AuthModule.refreshUser();
    } else {
      Vue.toasted.error(
        i18n.t('TL_USER_EXPORT_SOMETHING_BLEW_UP').toString(),
        {
          duration: 10000,
          position: 'bottom-center',
        },
      );
    }
    this.context.commit('setIsLoading', false);
  }
}

export default getModule(BillingInfoModule);
