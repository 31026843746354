
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ExplorerSearchNavItem extends Vue {
  //todo show page links is hardcoded to true
  searchText = '';
  search() {
    if (this.searchText) {
      //don't just pass in the path otherwise if you search for 'export' it with throw a duplicated navigation route exception
      // the catch err is the recommended approach to handling duplicate searches see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
      this.$router
        .push({
          name: 'folderwithitem',
          params: {
            folder: 'search',
            selecteditemid: this.searchText,
          },
        })
        .catch((err) => {});
    } else {
      this.$router.push('/explorer/all').catch((error) => {
        if (error.name != 'NavigationDuplicated') {
          throw error;
        }
      });
    }
  }
}
