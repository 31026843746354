






import { Component, Vue } from 'vue-property-decorator';
import leftsidebarcontent from './LeftSidebarContent.vue';

@Component({
  components: {
    leftsidebarcontent,
  },
})
export default class LeftSidebar extends Vue {}
