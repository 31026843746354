




















import { Component, Vue } from 'vue-property-decorator';
import AuthModule from '@/store/modules/AuthModule';
import explorersearchnavitem from '@/components/navbars/ExplorerSearchNavItem.vue';
import notificationsnavitem from '@/components/navbars/NotificationsNavItem.vue';
import upgradenavitem from '@/components/navbars/UpgradeNavItem.vue';
import helpnavitem from '@/components/navbars/HelpNavItem.vue';

@Component({
  components: {
    explorersearchnavitem,
    notificationsnavitem,
    upgradenavitem,
    helpnavitem,
  },
})
export default class AppHeader extends Vue {
  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }

  get showExplorerPageItems() {
    return this.$route.path.startsWith('/explorer/');
  }

  get isLoaded() {
    return AuthModule.isLoaded;
  }
  get mq() {
    // @ts-ignore
    return this.$mq;
  }
}
