// todo would be nice to add the font awesome icons in a separate file
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCogs,
  faCog,
  faBars,
  faSearch,
  faSignOutAlt,
  faCheck,
  faTabletAlt,
  faPlus,
  faUndo,
  faHome,
  faHeart,
  faTrash,
  faInfoCircle,
  faTag,
  faBook,
  faSlidersH,
  faWindowClose,
  faStar,
  faStarHalf,
  faList,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faCheckSquare,
  faTags,
  faFolder,
  faFile,
  faFolderOpen,
  faTv,
  faBan,
  faEdit,
  faPencilAlt,
  faBookmark,
  faHighlighter,
  faClock,
  faCut,
  faCopy,
  faTimes,
  faGlasses,
  faExclamationCircle,
  faFileDownload,
  faChevronRight,
  faBookReader,
  faDownload,
  faCircleNotch,
  faCheckCircle,
  faTimesCircle,
  faCreditCard,
  faReceipt,
  faEye,
  faEyeSlash,
  faQuestion,
  faFileExport,
  faClone,
  faEllipsisH,
  faEllipsisV,
  faSquare,
  faRedo,
  faUser,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

// <!-- The solid style is implicit -->
// <font-awesome-icon icon="user-secret" />
// but better to be explicit
// <font-awesome-icon :icon="['fas', 'user-secret']" />

import {
  faFolder as farFolder,
  faFolderOpen as farFolderOpen,
  faFileAlt as farFileAlt,
  faNewspaper,
  faStar as farStar,
  faPlusSquare as farPlusSquare,
} from '@fortawesome/free-regular-svg-icons';
// <font-awesome-icon :icon="['far', 'amazon']" />

import {
  faAmazon,
  faCcDinersClub,
  faCcAmex,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';

// <font-awesome-icon :icon="['fab', 'amazon']" />

import {
  faBook as farBook,
  faDownload as farDownload,
  faBell as farBell,
  faBookReader as farBookReader,
  faChartNetwork as farChartNetwork,
  faFileDownload as farFileDownload,
  faChevronDoubleDown as farChevronDoubleDown,
  faChevronDoubleUp as farChevronDoubleUp,
  faExclamationSquare as farExclamationSquare,
  faPowerOff as farPowerOff,
} from '@fortawesome/pro-regular-svg-icons';
// <font-awesome-icon :icon="['far', 'book']" />

import {
  faPlusCircle as fasPlusCircle,
  faMinusCircle as fasMinusCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faStarShooting as falStarShooting,
  faBell as falBell,
  faLongArrowAltRight as falLongArrowAltRight,
  faFileDownload as falFileDownload,
  faQuestionCircle as falQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
// <font-awesome-icon :icon="['fal', 'star']" />

import { faBookReader as fadBookReader } from '@fortawesome/pro-duotone-svg-icons';
// <font-awesome-icon :icon="['fad', 'book-reader']" />

library.add(
  faCogs,
  faEdit,
  faPencilAlt,
  faCog,
  faBars,
  faSearch,
  faSignOutAlt,
  faCheck,
  faTabletAlt,
  faAmazon,
  faPlus,
  faUndo,
  faHome,
  faHeart,
  faTrash,
  faInfoCircle,
  faTag,
  faBook,
  faSlidersH,
  faWindowClose,
  faStar,
  faStarHalf,
  faList,
  faCloudUploadAlt,
  faCloudDownloadAlt,
  faCheckSquare,
  faTags,
  faFolder,
  faFile,
  faFolderOpen,
  farFolder,
  farFolderOpen,
  farFileAlt,
  faTv,
  faBan,
  faBookmark,
  faHighlighter,
  faClock,
  faCut,
  faCopy,
  faTimes,
  faGlasses,
  faExclamationCircle,
  faFileDownload,
  faChevronRight,
  faBookReader,
  faNewspaper,
  farStar,
  falStarShooting,
  farBook,
  farDownload,
  faDownload,
  farBell,
  falBell,
  faCircleNotch,
  faCheckCircle,
  faTimesCircle,
  faCreditCard,
  faCcDinersClub,
  faCcAmex,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faReceipt,
  faEye,
  faEyeSlash,
  faQuestion,
  faFileExport,
  faClone,
  faEllipsisH,
  faEllipsisV,
  faSquare,
  farBookReader,
  fadBookReader,
  faRedo,
  farPlusSquare,
  faUser,
  faExclamationTriangle,
  falLongArrowAltRight,
  farChartNetwork,
  farFileDownload,
  falFileDownload,
  farChevronDoubleDown,
  farChevronDoubleUp,
  farExclamationSquare,
  farPowerOff,
  fasPlusCircle,
  fasMinusCircle,
  falQuestionCircle,
);
