












import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';

@Component
export default class NoExportsNotification extends Vue {
  get noNotifications() {
    return (
      ExportUiStateModule.exportsProcessingSummary.length +
        ExportUiStateModule.exportsCompleted.length ===
      0
    );
  }
}
