// Based off https://github.com/coding-blocks-archives/realworld-vue-typescript/tree/master/src/store
import axios from 'axios';
import Vue from 'vue';
import { IBulkGetExplorerDataResponse } from './types/responses/IBulkGetExplorerDataResponse';
import { IRecord } from './types/IRecord';
import { IExplorerPagingParams } from './types/IExplorerPagingParams';
import AuthModule from '@/store/modules/AuthModule';
import { IExplorerSettings } from './types/IExplorerSettings';
import { IFileUploadResponse } from './types/IFileUploadResponse';
import { IImportResult } from './types/IImportResult';
import { IImportDefinition } from './types/IImportDefinition';
import { IBulkGetSettingsResponse } from './types/responses/IBulkGetSettingsResponse';
import { IIntegrationAuth } from './types/IIntegrationAuth';
import { IIntegrationAuthUrlResult } from './types/IIntegrationAuthUrlResult';
import { IAmazonSettings } from './types/IAmazonSettings';
import { IBookCollectionNew } from './types/IBookCollectionNew';
//import { IBookCollectionSelected } from './types/IBookCollectionSelected';
import { IAddUnAttachedNote } from './types/IAddUnAttachedNote';
import { INewTag } from './types/INewTag';
import { ITag } from './types/ITag';
import { IIntegrationExportSettings } from './types/IIntegrationExportSettings';
import { IExportCriteria } from './types/IExportCriteria';
import { IBillingOverview } from './types/IBillingOverview';
import router from '@/router';
import { ILogin } from './types/ILogin';
import { ILoginResult } from './types/ILoginResult';
import { ISignup } from './types/ISignup';
import { ISignupResult } from './types/ISignupResult';
import { ISendForgotPasswordEmailResult } from './types/IForgotPasswordResult';
import { IVerifyPasswordResultCodeResult } from './types/IVerifyPasswordResultCodeResult';
import { IResetPasswordWithCode } from './types/IResetPasswordWithCode';
import { IUpdatePasswordWithCodeResult } from './types/IUpdatePasswordWithCodeResult';
import { IUpdatePassword } from './types/IUpdatePassword';
import { IUpdatePasswordResult } from './types/IUpdatePasswordResult';
import { IChangeUsernameResult } from './types/IChangeUsernameResult';
import { IChangeEmailResult } from './types/IChangeEmailResult';
import { IDeleteAccountResult } from './types/IDeleteAccountResult';
import { IRemoveTagResponse } from './types/IRemoveTagResponse';
import { IModifyBookCollections } from './types/IModifyBookCollections';
import { IUserProfile } from './types/IUserProfile';
import { ICreateCheckoutSessionResponse } from './types/ICreateCheckoutSessionResponse';
import { ICreatePortalSessionResponse } from './types/ICreatePortalSessionResponse';
import { IResetExportedRecords } from './types/IResetExportedRecords';
import { IExportPrecheckResponse } from './types/IExportPrecheckResponse';
import i18n from '@/i18n';

export const cioApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export const cioApiNoIntercept = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

cioApi.interceptors.request.use(
  async (response) => {
    await AuthModule.refreshTokenIfRequired();
    const accessToken = AuthModule.idToken;
    if (accessToken) {
      response.headers.Authorization = `Bearer ${accessToken}`;
    }
    return response;
  },
  (err) => {
    console.error('Intercepted Request Error', err);
    return Promise.reject(err);
  },
);

cioApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('Intercepted Response Error:', error);
    console.log(
      'Intercepted Response Error Status:',
      error?.response?.status,
    );
    console.log(
      'Intercepted Response Error Data:',
      error?.response?.data,
    );

    if (
      !error.response ||
      (error.response && error.response.status === 503)
    ) {
      Vue.toasted.error(
        i18n.t('TL_SHARED_CLIPPINGS_OFFLINE').toString(),
        {
          duration: 10000,
          position: 'bottom-center',
        },
      );
      router.push('/offline');
      //swallow errorb
    } else if (error.response.status === 401) {
      router.push('/login');
    } else if (error.response.status === 500) {
      Vue.toasted.error(
        i18n.t('TL_USER_EXPORT_SOMETHING_BLEW_UP').toString(),
        {
          duration: 10000,
          position: 'bottom-center',
        },
      );
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    } else {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  },
);

export async function fileImportUploadFile(formData: FormData) {
  const response = await cioApi.post('/userfileuploads', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data as IFileUploadResponse;
}

export async function deleteImport(fileuploadid: number) {
  try {
    const response = await cioApi.delete(
      `/userfileuploads/${fileuploadid}`,
    );
    return fileuploadid;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// export async function userSync() {
//   try {
//     const response = await cioApi.get('/usersync');
//     return response.data as boolean;
//   } catch (err) {
//     console.error(err);
//     return null;
//   }
// }

export async function startImport(importdef: IImportDefinition) {
  try {
    const response = await cioApi.get(
      '/import/' + importdef.uploadGuid,
    );
    return response.data as IImportResult;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createCheckoutSession(priceId: string) {
  const response = await cioApi.post(
    '/userpayments/create-checkout-session/',
    {
      priceId: priceId,
    },
  );
  return response.data as ICreateCheckoutSessionResponse;
}

export async function createPortalSession() {
  const response = await cioApi.post(
    '/userpayments/customer-portal/',
  );
  return response.data as ICreatePortalSessionResponse;
}

export async function loadSettingsData() {
  try {
    const response = await cioApi.get('/bulkgetsettings/');
    return response.data as IBulkGetSettingsResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function loadExplorerData() {
  try {
    const response = await cioApi.get('/bulkgetexplorerdata/');
    return response.data as IBulkGetExplorerDataResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function resetDeletedMemory() {
  try {
    const response = await cioApi.delete('/deletedrecordsmemory/');
    return true;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function deleteBook(bookid: number) {
  // Ignores the book
  try {
    const response = await cioApi.post('/IgnoredBooks/', {
      id: bookid,
    });
    return bookid;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function restoreBook(bookid: number) {
  // removes it from ignored books
  try {
    const response = await cioApi.delete(`/IgnoredBooks/${bookid}`);
    return bookid;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function toggleAutoSync(integrationId: number) {
  try {
    const auth: IIntegrationAuth = { IntegrationID: integrationId };
    const response = await cioApi.post(
      '/userintegrationsettings/toggleautosync/',
      auth,
    );
    // interesting that the api doesnt return anything here
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// We don't need to handle response because the redirect from the
// Web API Causes the whole page to reload
export async function authenticateIntegration(integrationId: number) {
  try {
    const auth: IIntegrationAuth = { IntegrationID: integrationId };
    const response = await cioApi.post('/thirdpartyauth/', auth);
    return response.data as IIntegrationAuthUrlResult;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function revokeIntegration(integrationId: number) {
  try {
    const auth: IIntegrationAuth = { IntegrationID: integrationId };
    const options = { data: auth };
    const response = await cioApi.delete('/thirdpartyauth/', options);
    // interesting that the API does not return anything
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function resetAllExportedRecordsForIntegration(
  integrationId: number,
) {
  try {
    const response = await cioApi.delete(
      '/userintegrationsettings/resetallexportrecordsforintegration/' +
        integrationId,
    );
    // interesting that we don't return anything
  } catch (error) {
    console.error(error);
    return;
  }
}

export async function resetExportedRecordsForFileStorageIntegration(
  resetExportedRecords: IResetExportedRecords,
) {
  try {
    const options = {
      data: resetExportedRecords,
    };
    const response = await cioApi.delete(
      '/userintegrationsettings/resetexportedrecordsforfilestorageintegration',
      options,
    );
    // interesting that we don't return anything
  } catch (error) {
    console.error(error);
    return;
  }
}

export async function emptyTrash() {
  try {
    const response = await cioApi.delete('/UserTrash/');
  } catch (error) {
    console.error(error);
    return null;
  }
}

// add tag and apply it to a single record
export async function addTag(tag: INewTag) {
  try {
    const response = await cioApi.post('/UserTags/', tag);
    return response.data as ITag;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// removes the tag from a single record
export async function removeTag(tag: INewTag) {
  try {
    const options = { data: tag };
    const response = await cioApi.delete('/UserTags/', options);
    return response.data as IRemoveTagResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// deletes the tag completely and from all records
export async function deleteTag(tagid: number) {
  try {
    const response = await cioApi.delete(
      `/UserTags/removeall/${tagid}`,
    );
    return tagid;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createRecord(record: IRecord) {
  try {
    const response = await cioApi.post(
      '/UserRecords/',
      JSON.stringify(record),
    );
    return response.data as IRecord;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function deleteRecord(record: IRecord) {
  try {
    const response = await cioApi.post('/userdeletedrecords/', {
      RecordId: record.RecordId,
    });
    return record;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateRecord(record: IRecord) {
  try {
    const response = await cioApi.put('/UserRecords/', record);
    record.Editing = false;
    return record;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function restoreRecord(record: IRecord) {
  try {
    const response = await cioApi.delete(
      `${'/userdeletedrecords/'}${record.RecordId}`,
    );
    return record;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function restoreAllRecords() {
  try {
    const response = await cioApi.delete('/userdeletedrecords/');
    return true;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function addAttachedNote(record: IRecord) {
  try {
    const response = await cioApi.post('/userattachednotes/', record);
    return response.data as IRecord;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function addUnAttachedNote(note: IAddUnAttachedNote) {
  try {
    const response = await cioApi.post('/userunattachednotes/', note);
    return response.data as IRecord;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateAttachedNote(record: IRecord) {
  try {
    const response = await cioApi.put('/userattachednotes/', record);
    return record;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function exportPrechecks(exportModel: IExportCriteria) {
  try {
    const response = await cioApi.post(
      '/userexport/exportprechecks',
      exportModel,
    );
    return response.data as IExportPrecheckResponse;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createExportJob(exportModel: IExportCriteria) {
  try {
    const response = await cioApi.post('/userexport/', exportModel);
    return response.data.ExportHistoryId as number;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function cancelCurentExportJob(exportHistoryId: number) {
  try {
    const response = await cioApi.delete(
      `/userexport/${exportHistoryId}`,
    );
    return exportHistoryId;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function cancelPreviousExportJobs() {
  try {
    const response = await cioApi.delete(`/userexport/`);
    return true;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function deleteAttachedNote(record: IRecord) {
  try {
    const response = await cioApi.post('/userdeletedrecords/', {
      RecordId: record.RecordId,
    });
    return record;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function unlinkAttachedNote(record: IRecord) {
  try {
    const response = await cioApi.delete(
      `${'/userattachednotes/'}${record.RecordId}`,
    );
    return record;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function addBookCollection(
  newBookCollection: IBookCollectionNew,
) {
  try {
    const response = await cioApi.post(
      '/UserBookCollections/',
      newBookCollection,
    );
    return response.data as IBookCollectionNew;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateCollectionBooks(
  newBookCollection: IBookCollectionNew,
) {
  try {
    const response = await cioApi.put(
      '/UserBookCollections/',
      newBookCollection,
    );
    return response.data as IBookCollectionNew;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// export async function addBookToCollection(
//   collection: IBookCollectionSelected,
// ) {
//   try {
//     const response = await cioApi.post(
//       `/UserBooksInCollections/${collection.BookCollectionName}/book/${collection.BookID}`,
//     );
//     return collection;
//   } catch (error) {
//     console.error(error);
//     return null;
//   }
// }

export async function deleteBookCollection(BookCollectionID: number) {
  try {
    const response = await cioApi.delete(
      `/UserBookCollections/${BookCollectionID}`,
    );
    return BookCollectionID;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// export async function removeBookFromCollection(
//   collection: IBookCollectionSelected,
// ) {
//   try {
//     const response = await cioApi.delete(
//       `/UserBooksInCollections/${collection.BookCollectionName}/book/${collection.BookID}`,
//     );
//     return collection;
//   } catch (error) {
//     console.error(error);
//     return null;
//   }
// }

export async function updateBookCollections(
  collections: IModifyBookCollections,
) {
  try {
    const response = await cioApi.post(
      '/UserBooksInCollections/',
      collections,
    );
    return collections;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateAmazonKindleEmailAddress(
  amazonsettings: IAmazonSettings,
) {
  try {
    const response = await cioApi.post(
      '/useramazonsettings/',
      amazonsettings,
    );
    // interesting that the api doesn't return anything
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function resetAccount() {
  try {
    const response = await cioApi.post('/resetaccount/');
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function loadRecords(urlparams: IExplorerPagingParams) {
  try {
    const response = await cioApi.get('/UserRecords/', {
      params: urlparams,
    });
    return response.data as IRecord[];
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function refreshUserProfile() {
  const response = await cioApi.get('/userprofile/');
  return response.data as IUserProfile;
}

export async function loadBillingInfo(sessionId: string) {
  try {
    const response = await cioApi.post('/userpayments/', {
      sessionId,
    });
    return response.data as IBillingOverview;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function saveExplorerSettings(
  explorerSettings: IExplorerSettings,
) {
  try {
    const response = await cioApi.post(
      '/UserExplorerSettings/',
      explorerSettings,
    );
    // I kind of cheat here as I return the explorerSettings that were passed in as the
    // controller does not return anything
    return explorerSettings;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function saveIntegrationExportSettings(
  settings: IIntegrationExportSettings,
) {
  try {
    const response = await cioApi.post(
      '/userintegrationsettings/',
      settings,
    );
    return settings;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function deleteAccount(reasonforleaving: string) {
  const response = await cioApi.post('/UserDelete/', {
    reasonforleaving,
  });
  return response.data as IDeleteAccountResult;
}

export async function login(login: ILogin) {
  const response = await cioApi.post('/UserLogin/', login);
  return response.data as ILoginResult;
}

export async function exchangeRefreshToken(refreshToken: string) {
  //Notice we use cioApiNoIntercept as we don't want to use
  //the interceptor.  Doing so would prevent the RefreshToken call
  // completing as it end up in a endless loop as it would call
  // this function again if the access token has expired
  const response = await cioApiNoIntercept.post(
    '/UserRefreshToken/',
    {
      refreshToken,
    },
  );
  return response.data as ILoginResult;
}

export async function signUp(login: ISignup) {
  const response = await cioApi.post('/UserSignup/', login);
  return response.data as ISignupResult;
}

export async function sendForgotPasswordEmail(
  usernameoremail: string,
) {
  const response = await cioApi.post('/UserForgotPassword/', {
    usernameoremail,
  });
  return response.data as ISendForgotPasswordEmailResult;
}

export async function verifyPasswordResetCode(code: string) {
  const response = await cioApi.post(
    '/UserVerifyPasswordResetCode/',
    {
      code,
    },
  );
  return response.data as IVerifyPasswordResultCodeResult;
}

export async function updatePasswordWithCode(
  passwordWithCode: IResetPasswordWithCode,
) {
  const response = await cioApi.post(
    '/UserUpdatePasswordWithCode/',
    passwordWithCode,
  );
  return response.data as IUpdatePasswordWithCodeResult;
}

export async function updatePassword(password: IUpdatePassword) {
  const response = await cioApi.post(
    '/UserUpdatePassword/',
    password,
  );
  return response.data as IUpdatePasswordResult;
}

export async function changeUsername(newusername: string) {
  const response = await cioApi.post('/UserChangeUsername/', {
    newusername,
  });
  return response.data as IChangeUsernameResult;
}

export async function changeEmail(newemail: string) {
  const response = await cioApi.post('/UserChangeEmail/', {
    newemail,
  });
  return response.data as IChangeEmailResult;
}

export async function changePreferredLanguage(
  PreferredLanguage: string,
) {
  const response = await cioApi.post('/UserPreferredLanguage/', {
    PreferredLanguage,
  });
  return PreferredLanguage;
}

export async function testOnline() {
  try {
    // deliberately use no intercept otherwise we would end up in an infinite loop
    const response = await cioApiNoIntercept.get('/TestOnline/');
    return true;
  } catch (error) {
    return false;
  }
}

export async function testUnauthorisedException() {
  //this should get handled by the response interceptor
  AuthModule.clearUser();
  const response = await cioApi.get('/TestUnauthorisedException/');
}

export async function testUnhandledException() {
  //this should get handled by the response interceptor
  const response = await cioApi.get('/TestUnhandledException/');
}
