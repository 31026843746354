




































































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { ITag } from '@/store/types/ITag';

import showhidetoggle from '@/pages/explorer/components/leftsidebar/ShowHideToggle.vue';
import leftsibebarshowall from '@/pages/explorer/components/leftsidebar/LeftSidebarShowAll.vue';

@Component({
  components: {
    showhidetoggle,
    leftsibebarshowall,
  },
})
export default class LeftSidebarTags extends Vue {
  menuExpanded = true;
  mouseOverTagID = -1;

  showAll = false;
  pageSize = 10;
  get pagedItems() {
    return this.showAll
      ? this.tags
      : this.tags.slice(0, this.pageSize);
  }

  get tags() {
    return [...ExplorerDataModule.Tags].sort((a, b) =>
      a.Title.localeCompare(b.Title),
    );
  }

  mouseEnter(TagId: number) {
    this.mouseOverTagID = TagId;
  }

  mouseLeave() {
    this.mouseOverTagID = -1;
  }

  deleteTag(tag: ITag) {
    ExplorerDataModule.deleteTag(tag);
  }
}
