import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from 'vuex-module-decorators';
import store from '@/store/store';
import * as api from '@/store/api';

import { IFileUploadResponse } from '../types/IFileUploadResponse';

@Module({ dynamic: true, store, name: 'fileupload' })
class FileUploadModule extends VuexModule {
  fileUploadProcessing = false;
  fileUploadError = '';
  fileUploadSucceeded = false;
  fileUploadGuid = '';

  @Mutation
  clear() {
    this.fileUploadProcessing = false;
    this.fileUploadError = '';
    this.fileUploadSucceeded = false;
    this.fileUploadGuid = '';
  }

  @Mutation
  resetFileUploadPage() {
    this.fileUploadProcessing = false;
    this.fileUploadSucceeded = false;
    this.fileUploadError = '';
    this.fileUploadGuid = '';
  }

  @Mutation
  setFileUploadProcessing() {
    this.fileUploadProcessing = true;
    this.fileUploadSucceeded = false;
    this.fileUploadError = '';
    this.fileUploadGuid = '';
  }

  @Mutation
  fileUploadSuccess(result: IFileUploadResponse) {
    this.fileUploadProcessing = false;
    this.fileUploadError = '';
    this.fileUploadSucceeded = true;
    this.fileUploadGuid = result.UploadGuid;
  }

  @Mutation
  fileUploadFailed(result: IFileUploadResponse) {
    this.fileUploadProcessing = false;
    this.fileUploadError = result.error;
    this.fileUploadSucceeded = false;
    this.fileUploadGuid = '';
  }

  @Action
  async uploadFile(formData: FormData) {
    try {
      this.context.commit('setFileUploadProcessing');
      const result = await api.fileImportUploadFile(formData);
      this.context.commit('fileUploadSuccess', result);
    } catch (error) {
      this.context.commit(
        'fileUploadFailed',
        error as IFileUploadResponse,
      );
    }
  }
}

export default getModule(FileUploadModule);
