












import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component
export default class ShowHideToggle extends Vue {
  @PropSync('expanded') isExpanded!: boolean;
  isHovered = false;
  handleHover(isHovered: boolean) {
    this.isHovered = isHovered;
  }
  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
