





































































































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import Axios from 'axios';
import { IImportHistory } from '@/store/types/IImportHistory';
import showhidetoggle from '@/pages/explorer/components/leftsidebar/ShowHideToggle.vue';
import leftsibebarshowall from '@/pages/explorer/components/leftsidebar/LeftSidebarShowAll.vue';

@Component({
  components: {
    showhidetoggle,
    leftsibebarshowall,
  },
})
export default class LeftSidebarImports extends Vue {
  menuExpanded = true;
  activefileuploadid = 0;
  mouseOverFileUploadId = -1;
  mouseOverDownloadFileUploadId = -1;

  showAll = false;
  pageSize = 5;
  get pagedItems() {
    return this.showAll
      ? this.imports
      : this.imports.slice(0, this.pageSize);
  }

  get imports() {
    return ExplorerDataModule.Imports;
  }

  mouseEnterDownload(fileuploadid: number) {
    this.mouseOverDownloadFileUploadId = fileuploadid;
  }

  mouseLeaveDownload() {
    this.mouseOverDownloadFileUploadId = -1;
  }

  mouseEnter(fileuploadid: number) {
    this.mouseOverFileUploadId = fileuploadid;
  }

  mouseLeave() {
    this.mouseOverFileUploadId = -1;
  }

  setActive(FileUploadId: number) {
    this.activefileuploadid = FileUploadId;
  }

  getDownloadLocation(FileUploadGuid: string) {
    return (
      process.env.VUE_APP_API_URL +
      '/FileBackup?fileuploadguid=' +
      FileUploadGuid
    );
  }

  downloadFile(importHistory: IImportHistory) {
    const url = this.getDownloadLocation(
      importHistory.FileUploadGuid,
    );
    Axios.get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = importHistory.OriginalFileName;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  }

  async deleteUpload(FileUploadId: number) {
    await ExplorerDataModule.deleteImport({
      id: FileUploadId,
      modal: this.$bvModal,
    });
  }
}
