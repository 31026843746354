





















import {
  Component,
  Prop,
  PropSync,
  Vue,
} from 'vue-property-decorator';
@Component
export default class LeftSibebarShowAll extends Vue {
  @Prop() pagesize!: number;
  @Prop() itemcount!: number;
  @Prop() title!: string;
  @PropSync('showall') showAll!: boolean;

  get displayShowAll() {
    return !this.showAll && this.itemcount > this.pagesize;
  }
}
