

























import { Component, Vue } from 'vue-property-decorator';
import LayoutModule from '@/store/modules/LayoutModule';

@Component
export default class BaseLayout extends Vue {
  get leftSidebarOpen() {
    return LayoutModule.leftsidebaropen;
  }

  // get showOverflowScrollbar() {
  //   return LayoutModule.showOverflowScrollbar;
  // }
}
