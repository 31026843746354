









































































































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import exportnotification from '@/components/notificationssidebar/ExportNotification.vue';

@Component({
  components: {
    exportnotification,
  },
})
export default class ExportsCompleteNotifications extends Vue {
  get exportsCompleted() {
    return ExportUiStateModule.exportsCompletedExcludingDismissed;
  }

  dismissNotification(exportHistoryId: number) {
    ExportUiStateModule.dismissCompleted(exportHistoryId);
  }

  cancelExports() {
    ExportUiStateModule.cancelPreviousExportJobs();
  }
}
