



















































































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { IBooksInCollections } from '@/store/types/IBooksInCollections';
import {
  Component,
  Vue,
  Prop,
  PropSync,
} from 'vue-property-decorator';

@Component
export default class EditCollectionModal extends Vue {
  @PropSync('visible') isVisible!: boolean;
  @Prop() collection!: IBooksInCollections;
  collectionName = '';
  selectedBooks: number[] = [];

  load() {
    this.collectionName = this.collection.BookCollectionName;
    this.selectedBooks = this.collection.Books.map((b) => b.BookId);
  }

  cancel() {
    this.isVisible = false;
  }

  async deleteCollection() {
    await ExplorerDataModule.deleteBookCollection({
      id: this.collection.BookCollectionID,
      modal: this.$bvModal,
    });
    this.isVisible = false;
  }

  get validateCollectionName() {
    if (this.collectionName) {
      return (
        this.collectionName!.length > 0 &&
        this.collectionName!.length <= 250
      );
    } else {
      return null;
    }
  }

  get books() {
    return ExplorerDataModule.nonDeletedBooks;
  }

  async updateCollectionBooks() {
    //todo need to check if this collection name is already used
    if (this.validateCollectionName) {
      await ExplorerDataModule.updateCollectionBooks({
        BookCollectionName: this.collectionName,
        BookIds: this.selectedBooks,
        BookCollectionID: this.collection.BookCollectionID,
      });
      this.isVisible = false;
    }
  }
}
