import {
  VuexModule,
  Module,
  getModule,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { IUserProfile } from '../types/IUserProfile';

@Module({ dynamic: true, store, name: 'featureFlags' })
class FeatureFlagsModule extends VuexModule {
  featureFlagShowBookImages = false;
  @Mutation
  setFeatureFlags(profile: IUserProfile) {
    this.featureFlagShowBookImages =
      profile.Email === 'jculverwell@gmail.com';
  }
}
export default getModule(FeatureFlagsModule);
