// If you want to convert the epoch to a date online use:
// https://www.epochconverter.com/

import jwt_decode from 'jwt-decode';

export const jwtExpired = (idToken: string) =>
  Date.now() >= getTokenExpiryEpochFromJwt(idToken) * 1000;
export const getTokenExpiryEpochFromJwt = (idToken: string) =>
  jwt_decode<any>(idToken).exp as number;
export const getTokenExpiryDate = (idToken: string) =>
  new Date(getTokenExpiryEpochFromJwt(idToken) * 1000);
export const getTokenExpiryDateFromEpoch = (epoch: number) =>
  new Date(epoch * 1000);
