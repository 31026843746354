import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from 'vuex-module-decorators';
import store from '@/store/store';
import * as api from '@/store/api';
import router from '@/router';
import { IImportResult } from '@/store/types/IImportResult';
import { IImportDefinition } from '@/store/types/IImportDefinition';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';

@Module({ dynamic: true, store, name: 'importuistate' })
class ImportUiStateModule extends VuexModule {
  isImporting = false;
  importComplete = false;
  importResult: IImportResult | null = null;
  importDefinition: IImportDefinition | null = null;

  @Mutation
  clear() {
    this.isImporting = false;
    this.importComplete = false;
    this.importResult = null;
    this.importDefinition = null;
  }

  @Mutation
  importCompleted(importResult: IImportResult) {
    this.isImporting = false;
    this.importResult = importResult;
    this.importComplete = true;
  }

  @Mutation
  importStarted(importDefinition: IImportDefinition) {
    this.isImporting = true;
    this.importComplete = false;
    this.importResult = null;
    this.importDefinition = importDefinition;
  }

  @Action
  async startImport(importDef: IImportDefinition) {
    this.context.commit('importStarted', importDef);
    const importResult = await api.startImport(importDef);
    this.context.commit('importCompleted', importResult);
    if (importResult!.ImportSuccessful) {
      await ExplorerDataModule.loadExplorerDataAndRecords();
      router.push('/import/complete');
    } else {
      router.push('/import/failed');
    }
  }
}

export default getModule(ImportUiStateModule);
