



















import { Component, Vue } from 'vue-property-decorator';
import exportnotifications from '@/components/notificationssidebar/ExportNotifications.vue';
import notificationsheader from '@/components/notificationssidebar/NotificationsHeader.vue';

import AuthModule from '@/store/modules/AuthModule';

@Component({
  components: {
    notificationsheader,
    exportnotifications,
  },
})
export default class ExportNotifications extends Vue {
  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }
}
