


















































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import leftsidebarbookcollection from './LeftSidebarBookCollection.vue';
import leftsidebardeletedbooks from './LeftSidebarDeletedBooks.vue';
import { IModalRequest } from '@/store/types/IModalRequest';
import showhidetoggle from '@/pages/explorer/components/leftsidebar/ShowHideToggle.vue';

@Component({
  components: {
    leftsidebarbookcollection,
    leftsidebardeletedbooks,
    showhidetoggle,
  },
})
export default class LeftSidebarRecent extends Vue {
  mouseOverBookID = -1;
  menuExpanded = true;

  get recentBooks() {
    return ExplorerDataModule.recentBooks;
  }

  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }

  mouseEnterBook(BookId: number) {
    this.mouseOverBookID = BookId;
  }

  mouseLeaveBook() {
    this.mouseOverBookID = -1;
  }

  deleteBook(bookid: number) {
    ExplorerDataModule.deleteBook({
      id: bookid,
      modal: this.$bvModal,
    } as IModalRequest);
  }
}
