// Based off https://github.com/coding-blocks-archives/realworld-vue-typescript/blob/master/src/store/index.ts
// which doesn't define the store

import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {},
  state: {},
  mutations: {},
  actions: {},
});
