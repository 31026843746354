





































































































import { Component, Vue } from 'vue-property-decorator';
import avatar from '@/components/navbars/avatar.vue';
import AuthModule from '@/store/modules/AuthModule';
import LayoutModule from '@/store/modules/LayoutModule';

@Component({
  components: {
    avatar,
  },
})
export default class LeftNavbar extends Vue {
  hideLeftSidebarOnMobile() {
    this.hideTooltips();
    LayoutModule.hideLeftSidebarOnMobile();
  }

  help() {
    // @ts-ignore
    HelpCrunch('openChat');
  }

  hideTooltips() {
    //needed for mobile
    setTimeout(() => {
      this.$root.$emit('bv::hide::tooltip');
    }, 2000);
  }

  toggleSidebar() {
    this.hideTooltips();
    LayoutModule.toggleLeftSideBar();
  }

  get userProfile() {
    return AuthModule.profile; // as IUserProfile;
  }
  get isLoaded() {
    return AuthModule.isLoaded;
  }
  logOut() {
    AuthModule.logout();
  }

  get exportlink() {
    if (this.$route.path.includes('/wizard')) {
      return this.$route.path;
    } else if (this.$route.path.includes('/complete')) {
      return '/explorer/all/export/wizard';
    } else if (this.$route.path.includes('/explorer/')) {
      return `${this.$route.path}/export/wizard`;
    } else {
      return '/explorer/all/export/wizard';
    }
  }
}
