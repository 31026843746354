// Naming Conventions
// Action=DeleteBook
// Mutation=DeleteBookSuccess

import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { IBook } from '@/store/types/IBook';
import * as api from '@/store/api';
import { IBookCollection } from '@/store/types/IBookCollection';
import { ITag } from '@/store/types/ITag';
import { IExplorerSettings } from '@/store/types/IExplorerSettings';
import { IBooksToBookCollections } from '@/store/types/IBooksToBookCollections';
import { IExplorerPagingParams } from '@/store/types/IExplorerPagingParams';
import { IRecord } from '@/store/types/IRecord';
import { IBulkGetExplorerDataResponse } from '@/store/types/responses/IBulkGetExplorerDataResponse';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IBooksInCollections } from '@/store/types/IBooksInCollections';
import { IBookCollectionNew } from '@/store/types/IBookCollectionNew';
//import { IBookCollectionSelected } from '@/store/types/IBookCollectionSelected';
import { INewTag } from '@/store/types/INewTag';
import { ITagComposite } from '@/store/types/ITagComposite';
import { IExportCriteria } from '@/store/types/IExportCriteria';
import { IExportHistory } from '@/store/types/IExportHistory';
import { IImportHistory } from '@/store/types/IImportHistory';
import router from '@/router';
import { IRemoveTagResponse } from '../types/IRemoveTagResponse';
import i18n from '@/i18n';
import { IModalRequest } from '../types/IModalRequest';
import { IModifyBookCollections } from '../types/IModifyBookCollections';
const PAGING_SIZE = 50;

@Module({ dynamic: true, store, name: 'explorerdata' })
class ExplorerDataModule extends VuexModule {
  isLoading = true;
  isLoadingMore = false;
  isInitialised = false;
  BookCollections: IBookCollection[] = [];
  Tags: ITag[] = [];
  Books: IBook[] = [];
  Exports: IExportHistory[] = [];
  Imports: IImportHistory[] = [];
  ExplorerSettings: IExplorerSettings | null = null;
  BooksToBookCollections: IBooksToBookCollections[] = [];
  Records: IRecord[] = [];
  PagingComplete = false;
  // explorer ui state
  // should this go in here on another module?

  @Mutation
  setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  @Mutation
  setIsLoadingMore(value: boolean): void {
    this.isLoadingMore = value;
  }

  @Mutation
  clear() {
    this.isLoading = true;
    this.isLoadingMore = false;
    this.isInitialised = false;
    this.BookCollections = [];
    this.Tags = [];
    this.Books = [];
    this.Exports = [];
    this.Imports = [];
    this.ExplorerSettings = null;
    this.BooksToBookCollections = [];
    this.Records = [];
    this.PagingComplete = false;
  }

  @Mutation
  explorerDataLoaded(response: IBulkGetExplorerDataResponse) {
    this.BookCollections = response.BookCollections;
    this.Tags = response.Tags;
    this.Books = response.Books;
    this.ExplorerSettings = response.ExplorerSettings; // It may make more sense for this to live in the UserSettings Module
    this.BooksToBookCollections = response.BooksToBookCollections;
    this.Exports = response.Exports;
    this.Imports = response.Imports;
    this.isInitialised = true;
  }

  @Mutation
  updateRecordSuccess(record: IRecord) {
    if (record.ParentRecordId !== -1) {
      const oldrecord = this.Records.find(
        (r) => r.RecordId === record.ParentRecordId,
      );

      const oldnote = oldrecord!.Notes.find(
        (r) => r.RecordId === record.RecordId,
      );
      Object.assign(oldnote, record);
    } else {
      const oldrecord = this.Records.find(
        (r) => r.RecordId === record.RecordId,
      );
      Object.assign(oldrecord, record);
    }
  }

  @Action
  async updateRecord(record: IRecord) {
    const results = await api.updateRecord(record);
    if (results) {
      this.context.commit('updateRecordSuccess', results);
    }
  }

  @Action
  async toggleFavorite(record: IRecord) {
    const results = await api.updateRecord(record);
    if (results) {
      this.context.commit('updateRecordSuccess', results);
      if (
        ExplorerUiStateModule.selectedFolder.FolderName ===
          'favorites' &&
        !record.Favorite
      ) {
        // if we are in the favorites view and the record
        // is not longer a favorite - remove it
        this.context.commit('deleteRecordSuccess', record);
      }
    }
  }

  @Mutation
  deleteImportSuccess(fileuploadid: number) {
    this.Imports = this.Imports.filter(
      (r) => r.FileUploadId !== fileuploadid,
    );
  }

  @Action
  async deleteImport(request: IModalRequest) {
    const { id: fileuploadid, modal } = request;
    modal
      .msgBoxConfirm(
        i18n.t('TL_DIALOG_CONFIRM_DELETE_IMPORT').toString(),
        {
          title: i18n.t('TL_SHARED_DELETE_IMPORT').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: i18n.t('TL_DIALOG_YES').toString(),
          cancelTitle: i18n.t('TL_DIALOG_NO').toString(),
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true,
        },
      )
      .then(async (confirmed) => {
        if (confirmed) {
          const results = await api.deleteImport(fileuploadid);
          if (results) {
            this.context.commit('deleteImportSuccess', results);
            // This is calling an action
            await this.context.dispatch('loadExplorerData');
            // navigating to all will cause the records to reload
            router.push('/explorer/all');
          }

          modal.msgBoxOk(
            i18n
              .t('TL_DIALOG_IMPORT_SUCCESSFULLY_DELETED')
              .toString(),
            {
              title: i18n.t('TL_DIALOG_IMPORT_DELETED').toString(),
              size: 'md',
              buttonSize: 'md',
              okVariant: 'danger',
              okTitle: i18n.t('TL_DIALOG_OK').toString(),
              footerClass: 'p-2',
              hideHeaderClose: true,
              centered: true,
            },
          );
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }

  @Mutation
  deleteRecordSuccess(record: IRecord) {
    this.Records = this.Records.filter(
      (r) => r.RecordId !== record.RecordId,
    );
  }

  @Action
  async deleteRecord(record: IRecord) {
    const results = await api.deleteRecord(record);
    if (results) {
      this.context.commit('deleteRecordSuccess', results);
    }
  }

  @Mutation
  restoreRecordSuccess(record: IRecord) {
    this.Records = this.Records.filter(
      (r) => r.RecordId !== record.RecordId,
    );
  }

  @Action
  async restoreRecord(record: IRecord) {
    const results = await api.restoreRecord(record);
    if (results) {
      this.context.commit('restoreRecordSuccess', results);
    }
  }

  @Mutation
  restoreAllRecordsSuccess() {
    this.Records = [];
  }

  @Action
  async restoreAllRecords() {
    const results = await api.restoreAllRecords();
    if (results) {
      this.context.commit('restoreAllRecordsSuccess');
    }
  }

  @Mutation
  recordsLoaded(records: IRecord[]) {
    this.Records = records;
    this.PagingComplete = records.length < PAGING_SIZE;
  }

  @Mutation
  recordsLoadedMore(records: IRecord[]) {
    //this.Records = response;
    this.Records = this.Records.concat(records);
    this.PagingComplete = records.length < PAGING_SIZE;
  }

  @Action
  async loadExplorerDataAndRecords() {
    await this.context.dispatch('loadExplorerData');
    await this.context.dispatch('loadRecords');
  }

  @Action
  async loadExplorerData() {
    this.context.commit('setIsLoading', true);
    const response = await api.loadExplorerData();
    this.context.commit('explorerDataLoaded', response);
    this.context.commit('setIsLoading', false);
  }

  @Action
  async loadExplorerDataAndSettingsData() {
    await this.context.dispatch('loadExplorerData');
    await UserSettingsModule.loadSettingsData();
  }

  @Action
  async resetAccount() {
    const response = await api.resetAccount();
    await this.context.dispatch('loadExplorerDataAndSettingsData');
  }

  @Action
  async loadRecords() {
    await ExplorerUiStateModule.resetPage();
    this.context.commit('setIsLoading', true);
    const response = await api.loadRecords(this.loadRecordParams);
    this.context.commit('recordsLoaded', response);
    this.context.commit('setIsLoading', false);
  }

  @Action
  async loadMoreRecords() {
    await ExplorerUiStateModule.incrementPage();
    this.context.commit('setIsLoadingMore', true);
    const response = await api.loadRecords(this.loadRecordParams);
    this.context.commit('recordsLoadedMore', response);
    this.context.commit('setIsLoadingMore', false);
  }

  @Mutation
  updateReadingMode() {
    this.ExplorerSettings!.ReadingModeOn = !this.ExplorerSettings!
      .ReadingModeOn;
  }

  @Action
  async toggleReadingMode() {
    this.context.commit('updateReadingMode');
    await api.saveExplorerSettings(this.ExplorerSettings!);
  }

  @Mutation
  updateExplorerSettings(settings: IExplorerSettings) {
    console.log('updating explorer settings', settings);
    this.ExplorerSettings = settings;
  }

  @Action
  async resetExplorerSettings() {
    const resetSettings = {
      BookMarks: false,
      Clippings: true,
      Highlights: true,
      Notes: true,
      SortOrder: 'BookTitle Asc',
      ThenByOrder: 'LocationDecimal Asc',
      ReadingModeOn: true,
      DisplayHighlightColor: true,
      DisplayRecordSeparator: true,
    } as IExplorerSettings;
    this.context.dispatch('saveExplorerSettings', resetSettings);
  }

  @Action
  async saveExplorerSettings(settings: IExplorerSettings) {
    this.context.commit('updateExplorerSettings', settings);
    await api.saveExplorerSettings(settings);
    //this.context.dispatch('loadRecords');
  }

  @Mutation
  bookRestored(bookId: number) {
    const book = this.Books.find((b) => b.BookId === bookId);
    book!.Deleted = false;
  }

  @Mutation
  bookDeleted(bookId: number) {
    const book = this.Books.find((b) => b.BookId === bookId);
    book!.Deleted = true;
  }

  // modal: BvModal
  @Action
  async deleteBook(request: IModalRequest) {
    const { id: bookId, modal } = request;
    modal
      .msgBoxConfirm(
        i18n.t('TL_DIALOG_CONFIRM_DELETE_BOOK').toString(),
        {
          title: i18n.t('TL_SHARED_DELETE_BOOK').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: i18n.t('TL_DIALOG_YES').toString(),
          cancelTitle: i18n.t('TL_DIALOG_NO').toString(),
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true,
        },
      )
      .then(async (confirmed) => {
        if (confirmed) {
          this.context.commit('bookDeleted', bookId);
          await api.deleteBook(bookId);
          // This is calling an action
          await this.context.dispatch('loadExplorerData');
          // navigating to all will cause the records to reload
          router.push('/explorer/all');

          modal.msgBoxOk(
            i18n.t('TL_DIALOG_BOOK_SUCCESSFULLY_DELETED').toString(),
            {
              title: i18n.t('TL_DIALOG_BOOK_DELETED').toString(),
              size: 'md',
              buttonSize: 'md',
              okVariant: 'danger',
              okTitle: i18n.t('TL_DIALOG_OK').toString(),
              footerClass: 'p-2',
              hideHeaderClose: true,
              centered: true,
            },
          );
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }

  @Action
  async restoreBook(request: IModalRequest) {
    const { id: bookId, modal } = request;
    this.context.commit('bookRestored', bookId);
    await api.restoreBook(bookId);
    await this.context.dispatch('loadExplorerDataAndRecords');

    modal.msgBoxOk(
      i18n.t('TL_DIALOG_BOOK_SUCCESSFULLY_RESTORED').toString(),
      {
        title: i18n.t('TL_DIALOG_BOOK_RESTORED').toString(),
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: i18n.t('TL_DIALOG_OK').toString(),
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true,
      },
    );
  }

  @Mutation
  addBookCollectionSuccess(newBookCollection: IBookCollectionNew) {
    const newbc: IBookCollection = {
      BookCollectionID: newBookCollection.BookCollectionID,
      BookCollectionName: newBookCollection.BookCollectionName,
    };
    this.BookCollections.push(newbc);

    newBookCollection.BookIds.forEach((bookId) => {
      // for each book in the collection map it to the bookcollection
      // and remove it from the uncategorised collection
      const newb2bc: IBooksToBookCollections = {
        BookCollectionID: newBookCollection.BookCollectionID,
        BookId: bookId,
      };
      this.BooksToBookCollections.push(newb2bc);

      this.BooksToBookCollections = this.BooksToBookCollections.filter(
        (item) =>
          !(item.BookCollectionID === -1 && item.BookId === bookId),
      );
    });
  }

  @Action
  async addBookCollection(newBookCollection: IBookCollectionNew) {
    const result = await api.addBookCollection(newBookCollection);
    this.context.commit('addBookCollectionSuccess', result);
  }

  @Mutation
  updateCollectionBooksSuccess(
    newBookCollection: IBookCollectionNew,
  ) {
    // const newbc: IBookCollection = {
    //   BookCollectionID: newBookCollection.BookCollectionID,
    //   BookCollectionName: newBookCollection.BookCollectionName,
    // };
    // this.BookCollections.push(newbc);

    const bookCollection = this.BookCollections.find(
      (c) =>
        c.BookCollectionID === newBookCollection.BookCollectionID,
    );
    // update the book collection anme
    bookCollection!.BookCollectionName =
      newBookCollection.BookCollectionName;

    //clear all the books in the book collection
    this.BooksToBookCollections = this.BooksToBookCollections.filter(
      (b) => b.BookCollectionID != newBookCollection.BookCollectionID,
    );

    newBookCollection.BookIds.forEach((bookId) => {
      // for each book in the collection, map it to the bookcollection
      // and remove it from the uncategorised collection
      const newb2bc: IBooksToBookCollections = {
        BookCollectionID: newBookCollection.BookCollectionID,
        BookId: bookId,
      };
      this.BooksToBookCollections.push(newb2bc);
      // remove from uncategorised
      this.BooksToBookCollections = this.BooksToBookCollections.filter(
        (item) =>
          !(item.BookCollectionID === -1 && item.BookId === bookId),
      );
    });
  }

  @Action
  async updateCollectionBooks(newBookCollection: IBookCollectionNew) {
    const result = await api.updateCollectionBooks(newBookCollection);
    this.context.commit('updateCollectionBooksSuccess', result);
  }

  @Mutation
  updateBookCollectionsSuccess(
    bookcollections: IModifyBookCollections,
  ) {
    //clear all the collections for the book
    this.BooksToBookCollections = this.BooksToBookCollections.filter(
      (b) => b.BookId != bookcollections.BookId,
    );

    if (bookcollections.CollectionIds.length === 0) {
      // if the book is in any collections add it to the uncategorised collection
      const newb2bc: IBooksToBookCollections = {
        BookCollectionID: -1,
        BookId: bookcollections.BookId,
      };
      this.BooksToBookCollections.push(newb2bc);
    } else {
      bookcollections.CollectionIds.forEach((collectionId) => {
        // for each book in the collection, map it to the bookcollection
        // and remove it from the uncategorised collection
        const newb2bc: IBooksToBookCollections = {
          BookCollectionID: collectionId,
          BookId: bookcollections.BookId,
        };
        this.BooksToBookCollections.push(newb2bc);
      });
    }
  }

  @Action
  async updateBookCollections(collections: IModifyBookCollections) {
    const result = await api.updateBookCollections(collections);
    this.context.commit('updateBookCollectionsSuccess', result);
  }

  // @Mutation
  // addedBookToCollection(bookCollection: IBookCollectionSelected) {
  //   // Adds a book to a collection and also removes it from the uncategorised collection
  //   const newb2bc2: IBooksToBookCollections = {
  //     BookCollectionID: bookCollection.BookCollectionID,
  //     BookId: bookCollection.BookID,
  //   };
  //   this.BooksToBookCollections.push(newb2bc2);
  //   this.BooksToBookCollections = this.BooksToBookCollections.filter(
  //     (item) =>
  //       !(
  //         item.BookCollectionID === -1 &&
  //         item.BookId === bookCollection.BookID
  //       ),
  //   );
  // }

  // @Mutation
  // removedBookFromCollection(bookCollection: IBookCollectionSelected) {
  //   this.BooksToBookCollections = this.BooksToBookCollections.filter(
  //     (item) =>
  //       !(
  //         item.BookCollectionID === bookCollection.BookCollectionID &&
  //         item.BookId === bookCollection.BookID
  //       ),
  //   );
  //   if (
  //     this.BooksToBookCollections.filter(
  //       (item) => item.BookId === bookCollection.BookID,
  //     ).length === 0
  //   ) {
  //     const newbookinuncategorised: IBooksToBookCollections = {
  //       BookCollectionID: -1,
  //       BookId: bookCollection.BookID,
  //     };
  //     this.BooksToBookCollections.push(newbookinuncategorised);
  //   }
  // }

  // @Action
  // async toggleBookCollection(
  //   bookCollectionSelected: IBookCollectionSelected,
  // ) {
  //   if (bookCollectionSelected.Selected) {
  //     const result = await api.addBookToCollection(
  //       bookCollectionSelected,
  //     );
  //     this.context.commit('addedBookToCollection', result);
  //   } else {
  //     const result = await api.removeBookFromCollection(
  //       bookCollectionSelected,
  //     );
  //     this.context.commit('removedBookFromCollection', result);
  //   }
  // }

  @Mutation
  removedBookCollection(BookCollectionID: number) {
    this.BookCollections = this.BookCollections.filter(
      (item) => item.BookCollectionID !== BookCollectionID,
    );
  }

  @Action
  async deleteBookCollection(request: IModalRequest) {
    const { id: BookCollectionID, modal } = request;
    modal
      .msgBoxConfirm(
        i18n.t('TL_DIALOG_CONFIRM_DELETE_COLLECTION').toString(),
        {
          title: i18n.t('TL_DIALOG_DELETE_COLLECTION').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: i18n.t('TL_DIALOG_YES').toString(),
          cancelTitle: i18n.t('TL_DIALOG_NO').toString(),
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true,
        },
      )
      .then(async (confirmed) => {
        if (confirmed) {
          const result = await api.deleteBookCollection(
            BookCollectionID,
          );
          this.context.commit(
            'removedBookCollection',
            BookCollectionID,
          );
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }

  @Mutation
  deleteAttachedNoteSuccess(record: IRecord) {
    const oldrecord = this.Records.find(
      (r) => r.RecordId === record.ParentRecordId,
    );
    const newrecord = Object.assign({}, oldrecord, {
      Notes: oldrecord!.Notes.filter(
        (note) => note.RecordId !== record.RecordId,
      ),
    });
    newrecord.HasNotes = newrecord.Notes.length > 0;
    Object.assign(oldrecord, newrecord);
  }

  @Action
  async deleteAttachedNote(record: IRecord) {
    const result = await api.deleteAttachedNote(record);
    if (result) {
      this.context.commit('deleteAttachedNoteSuccess', result);
    }
  }

  @Mutation
  addAttachedNoteSuccess(record: IRecord) {
    const oldrecord = this.Records.find(
      (r) => r.RecordId === record.ParentRecordId,
    );
    const newrecord = Object.assign({}, oldrecord, {
      Notes: [...oldrecord!.Notes, record],
      HasNotes: true,
    });
    Object.assign(oldrecord, newrecord);
  }

  @Action
  async addAttachedNote(record: IRecord) {
    const result = await api.addAttachedNote(record);
    if (result) {
      this.context.commit('addAttachedNoteSuccess', result);
    }
  }

  @Mutation
  addUnAttachedNoteSuccess(record: IRecord) {
    // add to start
    this.Records = [record, ...this.Records];
  }

  @Action
  async addUnAttachedNote(BookId: number) {
    const result = await api.addUnAttachedNote({ BookId });
    if (result) {
      this.context.commit('addUnAttachedNoteSuccess', result);
    }
  }

  @Mutation
  emptyTrashSuccess() {
    this.Records = [];
  }

  @Action
  async emptyTrash() {
    const result = await api.emptyTrash();
    this.context.commit('emptyTrashSuccess');
  }

  @Mutation
  addTagSuccess(tagcomp: ITagComposite) {
    // To avoid adding tags with the same name first remove and then add
    this.Tags = this.Tags.filter(
      (tag) => tag.Title !== tagcomp.tag.Title,
    );
    this.Tags.push(tagcomp.tag);

    const record = this.Records.find(
      (r) => r.RecordId === tagcomp.newTag.RecordId,
    );

    record!.TagsArray.push(tagcomp.tag.Title);
  }

  @Action
  async addTag(tag: INewTag) {
    const result = await api.addTag(tag);
    if (result) {
      this.context.commit('addTagSuccess', {
        tag: result,
        newTag: tag,
      });
    }
  }

  @Mutation
  removeTagSuccess(result: IRemoveTagResponse) {
    // remove the tag from the record
    const record = this.Records.find(
      (r) => r.RecordId === result.RecordId,
    );
    record!.TagsArray = record!.TagsArray.filter(
      (tt) => tt !== result.TagTitle,
    );
    if (!result.TagInUse) {
      // if it's do longer used by any records remove the tag itself
      this.Tags = this.Tags.filter(
        (tag) => tag.Title !== result.TagTitle,
      );
    }
  }

  @Action
  async removeTag(tag: INewTag) {
    const result = await api.removeTag(tag);
    if (result) {
      this.context.commit('removeTagSuccess', result);
    }
  }

  @Mutation
  deleteTagSuccess(deletedtag: ITag) {
    this.Tags = this.Tags.filter(
      (tag) => tag.TagId !== deletedtag.TagId,
    );
    this.Records = this.Records.map((r) => {
      r.TagsArray = r!.TagsArray.filter(
        (tt) => tt !== deletedtag.Title,
      );
      return r;
    });
  }

  @Action
  async deleteTag(tag: ITag) {
    const result = await api.deleteTag(tag.TagId);
    if (result) {
      this.context.commit('deleteTagSuccess', tag);
    }
  }

  get tagsTitleArray() {
    return this.Tags.map((tag) => tag.Title);
  }

  //This excludes the uncatergorised book collection
  get collectionIdsForBook() {
    return (bookId: number) =>
      this.BooksToBookCollections.filter(
        (item) =>
          item.BookId === bookId && item.BookCollectionID !== -1,
      ).map((bc) => bc.BookCollectionID);
  }

  //returns all the collections and whether the book is in them or not
  // get collectionsForBook(): IBookCollectionSelected[] {
  //   const retval: IBookCollectionSelected[] = [];

  //   this.BookCollections.forEach((bc) => {
  //     const bcSelected: IBookCollectionSelected = {
  //       BookID: Number(
  //         ExplorerUiStateModule.selectedFolder.SelectedId,
  //       ),
  //       BookCollectionID: bc.BookCollectionID,
  //       BookCollectionName: bc.BookCollectionName,
  //       Selected:
  //         this.BooksToBookCollections.filter(
  //           (item) =>
  //             item.BookId ===
  //               Number(
  //                 ExplorerUiStateModule.selectedFolder.SelectedId,
  //               ) && item.BookCollectionID === bc.BookCollectionID,
  //         ).length > 0,
  //     };
  //     retval.push(bcSelected);
  //   });

  //   retval.sort((a, b) =>
  //     a.BookCollectionName.localeCompare(b.BookCollectionName),
  //   );
  //   return retval;
  // }

  // One book can belong to many book bookcollections
  // One bookcollection can have many books
  get getBooksInCollections(): IBooksInCollections[] {
    // excludes deleted books
    return this.BookCollections.map((bookcollection) => {
      return {
        BookCollectionID: bookcollection.BookCollectionID,
        BookCollectionName: bookcollection.BookCollectionName,
        Visible: true, // might not need this anymore
        Books: this.BooksToBookCollections.filter(
          (b2bc) =>
            b2bc.BookCollectionID === bookcollection.BookCollectionID,
        )
          .map((b2bc) =>
            this.Books.find((book) => book.BookId === b2bc.BookId),
          )
          .filter((book) => book!.Deleted === false)
          .sort(this.sortBooks),
      } as IBooksInCollections;
    }).sort(this.sortBookCollections);
  }

  sortBookCollections(
    colA: IBookCollection,
    colB: IBookCollection,
  ): number {
    const nameA = colA.BookCollectionName.toUpperCase(); // ignore upper and lowercase
    const nameB = colB.BookCollectionName.toUpperCase(); // ignore upper and lowercase
    if (colA.BookCollectionID === -1) {
      return -1;
    }
    if (colB.BookCollectionID === -1) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // must be equal
    return 0;
  }

  sortBooks(a: IBook | undefined, b: IBook | undefined): number {
    if (a!.BookTitle.toLowerCase() < b!.BookTitle.toLowerCase()) {
      return -1;
    }
    if (a!.BookTitle.toLowerCase() > b!.BookTitle.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  //todo this looks very inefficient,lets refactor at somepoint
  get findTagId() {
    return (tagName: string) => {
      return this.Tags.find((t) => t.Title === tagName)?.TagId;
    };
  }

  get recentBooks() {
    return this.Books.filter((b) => !b.Deleted)
      .map((b) => b)
      .sort(
        (a, b) =>
          +new Date(b.LastHighlighted) - +new Date(a.LastHighlighted),
      )
      .slice(0, 5);
  }

  get nonDeletedBooks() {
    return this.Books.filter((b) => !b.Deleted);
  }

  get loadRecordParams(): IExplorerPagingParams {
    return {
      RecordFilterEntityId:
        ExplorerUiStateModule.selectedFolder.SelectedId,
      RecordFilterTypeId:
        ExplorerUiStateModule.selectedFolder.RecordFilterType,
      BookMarks: this.ExplorerSettings!.BookMarks,
      Clippings: this.ExplorerSettings!.Clippings,
      Highlights: this.ExplorerSettings!.Highlights,
      Notes: this.ExplorerSettings!.Notes,
      SortOrder: this.ExplorerSettings!.SortOrder,
      ThenByOrder: this.ExplorerSettings!.ThenByOrder,
      Page: ExplorerUiStateModule.pagenumber,
    } as IExplorerPagingParams;
  }

  get getExportCriteria(): IExportCriteria {
    const selectedIntegration =
      ExportUiStateModule.selectedIntegration;
    const selectedFolder = ExplorerUiStateModule.selectedFolder;
    const resetExportedRecords =
      ExportUiStateModule.resetExportedRecords;
    return {
      IntegrationTypeID: selectedIntegration!.IntegrationTypeID,
      IntegrationID: selectedIntegration!.IntegrationID,
      ExportFormatIntegrationID: selectedIntegration!
        .IntegrationExportSettings.ExportFormatIntegrationID,
      IntegrationName: selectedIntegration!.IntegrationName,
      RecordFilterTypeId: selectedFolder.RecordFilterType,
      RecordFilterEntityId: selectedFolder.SelectedId,
      RecordFilterEntityTitle: selectedFolder.SelectedTitle,
      ResetExportedRecords: resetExportedRecords,
    };
  }
}

export default getModule(ExplorerDataModule);
