var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.displayBookCollection())?_c('b-nav-item',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(_vm.handleHoverBookCollection),expression:"handleHoverBookCollection"}],on:{"click":function($event){return _vm.toggleMenuExpanded()}}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"11"}},[_c('span',{staticClass:"bookcollectiontitle"},[_c('font-awesome-icon',{staticClass:"mr-1",class:{ filtercolor: _vm.bookFilterApplied() },attrs:{"icon":_vm.bookcollectionicon,"fixed-width":""}}),_vm._v("\n          "+_vm._s(_vm.bookCollection.BookCollectionName)+"\n        ")],1)]),_vm._v(" "),_c('b-col',{staticClass:"text-right",attrs:{"cols":"1"}},[(
            _vm.isHoveredBookCollection &&
            _vm.bookCollection.BookCollectionID !== -1
          )?_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            _vm.$t('TL_LEFTSIDEBAR_BOOKCOLLECTION_EDIT_COLLECTION')
          ),expression:"\n            $t('TL_LEFTSIDEBAR_BOOKCOLLECTION_EDIT_COLLECTION')\n          ",modifiers:{"hover":true,"right":true}}],attrs:{"icon":"cog","fixed-width":""},on:{"click":function($event){return _vm.editBookCollection()}}}):_vm._e()],1)],1)],1):_vm._e(),_vm._v(" "),(
      _vm.displayBookCollection() && _vm.bookCollection.Books.length === 0
    )?_c('b-nav-item',{staticClass:"nav-item-indented"},[(_vm.menuExpanded)?_c('div',[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"info-circle","fixed-width":""}}),_vm._v("\n      "+_vm._s(_vm.$t('TL_BOOK_COLLECTION_NO_BOOKS'))+"\n    ")],1):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.menuExpanded)?_c('div',[_c('leftsidebarbookcollectionbooks',{attrs:{"filteredBooks":_vm.filteredBooks(),"bookFilterApplied":_vm.bookFilterApplied}})],1):_vm._e(),_vm._v(" "),_c('editcollectionmodal',{attrs:{"visible":_vm.collectionsModalVisible,"collection":_vm.bookCollection},on:{"update:visible":function($event){_vm.collectionsModalVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }