import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { IExplorerSelectedFolder } from '@/store/types/IExplorerSelectedFolder';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { ExportRecordFilterType } from '@/store/types/Enums';
import { ISelectFolder } from '@/store/types/ISelectFolder';
import moment from 'moment';

@Module({ dynamic: true, store, name: 'exploreruistate' })
class ExplorerUiStateModule extends VuexModule {
  selectedFolder: IExplorerSelectedFolder = {
    FolderName: 'none',
    RecordFilterType: ExportRecordFilterType.All,
    SelectedId: '',
    SelectedItem: undefined,
    SelectedTitle: '',
    headericon: '',
  };
  searchText = '';
  bookFilter = '';
  pagenumber = 1;
  pagingcomplete = false; // todo number sure I am doing anything with this yet

  @Mutation
  clear() {
    this.searchText = '';
    this.bookFilter = '';
    this.pagenumber = 1;
    this.pagingcomplete = false; // todo number sure I am doing anything with this yet
  }

  @Mutation
  setSearchText(searchtext: string): void {
    this.searchText = searchtext;
  }

  @Mutation
  setSelectedFolder(selectedFolder: IExplorerSelectedFolder): void {
    this.selectedFolder = selectedFolder;
  }

  @Mutation
  // this might be able to go into the component
  changeBookFilter(bookFilter: string): void {
    this.bookFilter = bookFilter;
  }

  @Mutation
  incrementPageNumber() {
    this.pagenumber++;
  }

  @Mutation
  resetPageNumber() {
    this.pagenumber = 1;
  }

  @Action
  async incrementPage() {
    this.context.commit('incrementPageNumber');
  }

  @Action
  async resetPage() {
    this.context.commit('resetPageNumber');
  }

  @Action
  selectFolder(folder: ISelectFolder): void {
    const selectedFolder: IExplorerSelectedFolder = {
      RecordFilterType: ExportRecordFilterType.All, //
      SelectedId: folder.selectedId,
      FolderName: folder.folderName,
      SelectedItem: undefined,
      SelectedTitle: '',
      headericon: '',
    };
    this.context.commit('setSearchText', '');
    // todo some of these might need translating
    switch (folder.folderName.toLowerCase()) {
      case 'all':
        (selectedFolder.RecordFilterType =
          ExportRecordFilterType.All),
          (selectedFolder.SelectedTitle = 'All');
        selectedFolder.headericon = 'home';
        break;
      case 'favorites':
        (selectedFolder.RecordFilterType =
          ExportRecordFilterType.Favorites),
          (selectedFolder.SelectedTitle = 'Favorites');
        selectedFolder.headericon = 'heart';
        break;
      case 'deleted':
        (selectedFolder.RecordFilterType =
          ExportRecordFilterType.Deleted),
          (selectedFolder.SelectedTitle = 'Deleted');
        selectedFolder.headericon = 'trash';
        break;
      case 'books':
        // alert(books.length);
        (selectedFolder.RecordFilterType =
          ExportRecordFilterType.Book),
          (selectedFolder.SelectedItem = ExplorerDataModule.Books.find(
            (book) =>
              book.BookId === Number(selectedFolder.SelectedId),
          ));
        selectedFolder.SelectedTitle =
          selectedFolder.SelectedItem == null
            ? ''
            : selectedFolder.SelectedItem.BookTitle;
        selectedFolder.headericon = 'book';
        break;
      case 'imports':
        (selectedFolder.RecordFilterType =
          ExportRecordFilterType.Upload),
          (selectedFolder.SelectedItem = ExplorerDataModule.Imports.find(
            (upload) =>
              upload.FileUploadId ===
              Number(selectedFolder.SelectedId),
          ));
        // todo dateuploadedUTC should be formatted like the line below
        // selectedFolder.SelectedTitle = retval.SelectedItem == null ? '' :  this._datePipe.transform(retval.SelectedItem.DateUploadedUTC, 'medium') + ' - ' + retval.SelectedItem.FileName;
        selectedFolder.SelectedTitle =
          selectedFolder.SelectedItem == null
            ? ''
            : moment(
                selectedFolder.SelectedItem.DateUploadedUTC,
              ).format('lll');
        selectedFolder.headericon = 'cloud-upload-alt';
        break;
      case 'tags':
        (selectedFolder.RecordFilterType =
          ExportRecordFilterType.Tag),
          (selectedFolder.SelectedItem = ExplorerDataModule.Tags.find(
            (tag) => tag.TagId === Number(selectedFolder.SelectedId),
          ));
        selectedFolder.SelectedTitle =
          selectedFolder.SelectedItem == null
            ? ''
            : selectedFolder.SelectedItem.Title;
        selectedFolder.headericon = 'tag';
        break;
      case 'search':
        this.context.commit(
          'setSearchText',
          selectedFolder.SelectedId,
        );
        (selectedFolder.RecordFilterType =
          ExportRecordFilterType.Search),
          (selectedFolder.SelectedTitle = selectedFolder.SelectedId);
        selectedFolder.headericon = 'search';
        break;
      default:
      // do nothing
    }

    this.context.commit('setSelectedFolder', selectedFolder);
    ExplorerDataModule.loadRecords();
  }
}

export default getModule(ExplorerUiStateModule);
