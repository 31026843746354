




















import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
@Component
export default class NotificationsNavItem extends Vue {
  get exportNotificationCount() {
    return ExportUiStateModule.exportNotificationCount;
  }
}
