import * as signalR from '@microsoft/signalr';
import AuthModule from '@/store/modules/AuthModule';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { IExportResult } from '@/store/types/IExportResult';
import { IExportProcessing } from '@/store/types/IExportProcessing';

// SIGNALR
const connection = new signalR.HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_API_URL + '/export')

  .withAutomaticReconnect([
    0,
    1000,
    5000,
    10000,
    30000,
    60000,
    120000,
    300000,
    600000,
    6000000,
  ])
  .configureLogging(signalR.LogLevel.Information) //todo set this back to information
  .build();

if (process.env.NODE_ENV === 'development') {
  // prevents the connection timing out when debugging
  // https://stackoverflow.com/questions/49816245/asp-net-core-2-1-server-timeout-while-debugging
  connection.serverTimeoutInMilliseconds = 100000; // 100 second
}

connection.onreconnecting((error: any) => {
  console.log(
    `Connection lost due to error "${error}". Reconnecting.`,
  );
});

//this gets called after all of the reconnect attempts have been exhausted
connection.onclose(async () => {
  console.log(`Connection Closed Starting Connection Again.`);
  await startConnectionIfNotStarted();
});

connection.onreconnected(async () => {
  await connection.invoke(
    'RegisterUser',
    AuthModule.profile!.UserGuid,
  );
});

connection.on(
  'updateprogress',
  async (progress: IExportProcessing) => {
    try {
      await ExportUiStateModule.updateProgress(progress);
    } catch (error) {
      console.error('Error updating progress', error);
    }
  },
);

connection.on(
  'exportcomplete',
  async (exportcomplete: IExportResult) => {
    try {
      await ExportUiStateModule.exportCompleted(exportcomplete);
    } catch (error) {
      console.error('Error in export complete', error);
    }
  },
);

export const startConnectionIfNotStarted = async () => {
  try {
    if (connection.state !== signalR.HubConnectionState.Connected) {
      await connection.start();
      console.log('Connection Started');
      console.log(
        `Registering User with: ${AuthModule.profile!.UserGuid}`,
      );
      await connection.invoke(
        'RegisterUser',
        AuthModule.profile!.UserGuid,
      );
    }
  } catch (error) {
    console.error('Error in Connection Started', error);
    setTimeout(startConnectionIfNotStarted, 10000);
  }
};
