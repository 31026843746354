import {
  VuexModule,
  Module,
  getModule,
  Mutation,
} from 'vuex-module-decorators';
import store from '@/store/store';

@Module({ dynamic: true, store, name: 'layout' })
class LayoutModule extends VuexModule {
  leftsidebaropen = true;
  windowWidth = window.innerWidth;

  @Mutation
  hideLeftSidebarOnMobile() {
    if (this.isMobile) {
      this.leftsidebaropen = false;
    }
  }

  @Mutation
  hideLeftSidebar() {
    this.leftsidebaropen = false;
  }

  @Mutation
  toggleLeftSideBar() {
    this.leftsidebaropen = !this.leftsidebaropen;
  }

  @Mutation
  closePanes() {
    this.leftsidebaropen = true;
  }

  @Mutation
  // this might be able to go into the component
  setWindowWidth(width: number): void {
    this.windowWidth = width;
  }

  // get showOverflowScrollbar() {
  //   return true;
  //   // !router.app.$route.path.startsWith('/explorer/') ||
  //   // router.app.$route.path.includes('/export/')
  // }

  get isMobile() {
    return this.windowWidth < 768;
  }

  get screenSize() {
    if (this.windowWidth < 576) {
      return 'xs';
    } else if (this.windowWidth < 768) {
      return 'sm';
    } else if (this.windowWidth < 992) {
      return 'md';
    } else if (this.windowWidth < 1200) {
      return 'lg';
    } else {
      return 'xl';
    }
  }
}

export default getModule(LayoutModule);
