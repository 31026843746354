import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { IExportResult } from '@/store/types/IExportResult';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import * as api from '@/store/api';
import { ExportFinalResult } from '@/store/types/Enums';
import router from '@/router';
import { IExportProcessing } from '../types/IExportProcessing';
import { IExportProcessingSummary } from '../types/IExportProcessingSummary';
import { ICancelExport } from '../types/ICancelExport';
import * as signalrService from '@/services/signalrservice';
import { IExportPrecheckResponse } from '../types/IExportPrecheckResponse';

@Module({ dynamic: true, store, name: 'exportuistate' })
class ExportUiStateModule extends VuexModule {
  //WIZARD VARIABLES
  selectedIntegrationID = 0;
  resetExportedRecords = false; //we still need this, it's used when user moves from a free to paid plan
  createExportJobProcessing = false;
  exportPrechecksProcessing = false;
  exportPrecheckResult: IExportPrecheckResponse | null = null;
  //END WIZARD VARIABLES
  refreshDashboard = false;
  dismissedCompleteNotifications: number[] = [];
  exportsProcessing: IExportProcessing[] = [];
  exportsCompleted: IExportResult[] = []; // export completed whilst client open, not does not include ones which completed whilst the client was shut

  @Mutation
  clear() {
    this.selectedIntegrationID = 0;
    this.resetExportedRecords = false;
    //END WIZARD VARIABLES
    this.dismissedCompleteNotifications = [];
    this.exportsProcessing = [];
    this.exportsCompleted = []; // export completed whilst client open, not does not include ones which completed whilst the client was shut
  }

  @Mutation
  selectIntegrationComplete(IntegrationId: number): void {
    this.selectedIntegrationID = IntegrationId;
    this.resetExportedRecords = false;
  }

  @Action
  async selectIntegration(IntegrationId: number): Promise<void> {
    this.context.commit('selectIntegrationComplete', IntegrationId);
    this.context.dispatch('exportPrechecks');
  }

  @Mutation
  clearCompletedSuccess() {
    this.exportsCompleted.forEach((exp) => {
      this.dismissedCompleteNotifications.push(exp.exportHistoryId);
    });
  }

  @Mutation
  exportCompleteResult(exportResult: IExportResult) {
    this.exportsCompleted.unshift(exportResult);
    this.exportsProcessing = this.exportsProcessing.filter(
      (e) => e.exportHistoryId !== exportResult.exportHistoryId,
    );
  }

  @Action
  async exportCompleted(exportResult: IExportResult) {
    console.log('Export Completed', exportResult);
    await ExplorerDataModule.loadExplorerDataAndRecords();
    await UserSettingsModule.loadSettingsData();
    this.context.commit('exportCompleteResult', exportResult);

    // todo line below is designed to stop autoexports redirecting
    // the user off their current page
    // the intent is that if the user started the export then
    // they should be redirected when it completes
    // if they haven't cancelled the page

    if (exportResult.exportInitiation === 'User') {
      if (exportResult.result !== ExportFinalResult.AllFailed) {
        router.push(`${exportResult.processingUrl}/complete`);
      } else {
        router.push(`${exportResult.processingUrl}/failed`);
      }
    }
  }

  @Mutation
  setResetExportedRecords(value: boolean) {
    this.resetExportedRecords = value;
  }
  @Mutation
  toggleResetExportedRecords() {
    this.resetExportedRecords = !this.resetExportedRecords;
  }

  @Mutation
  clearSelectedFolder() {
    this.selectedIntegrationID = 0;
  }

  @Mutation
  createExportJobStarted() {
    this.createExportJobProcessing = true;
  }

  @Mutation
  createExportJobSuccess(exportProcessingHistoryId: number) {
    this.refreshDashboard = true;
    this.createExportJobProcessing = false;
  }

  @Mutation
  createExportJobFail() {
    //todo
    this.refreshDashboard = false;
    this.createExportJobProcessing = false;
  }

  @Mutation
  exportPrechecksStarted() {
    this.exportPrechecksProcessing = true;
  }

  @Mutation
  exportPrechecksComplete(result: IExportPrecheckResponse) {
    this.exportPrechecksProcessing = false;
    this.exportPrecheckResult = result;
    this.resetExportedRecords =
      result.ShowWarningLastExportWasUsingFreePlan;
  }

  @Action
  async exportPrechecks() {
    if (this.selectedIntegrationID !== 0) {
      this.context.commit('exportPrechecksStarted');
      const exportModel = ExplorerDataModule.getExportCriteria;
      const result = await api.exportPrechecks(exportModel);
      this.context.commit('exportPrechecksComplete', result);
    }
  }

  @Action
  async createExportJob(url: string) {
    this.context.commit('createExportJobStarted');
    const exportModel = ExplorerDataModule.getExportCriteria;
    const result = await api.createExportJob(exportModel);
    // we load the explorer data tab here so the exports tab on the dashboard gets updated to queued
    await ExplorerDataModule.loadExplorerData();
    const baseUrl = window.location.pathname.substring(
      0,
      window.location.pathname.indexOf('wizard'),
    );
    const processingUrl = `${baseUrl}${result}/processing`;

    if (result) {
      signalrService.startConnectionIfNotStarted(); //don't await, takes too long
      this.context.commit('createExportJobSuccess', result);
      router.push(processingUrl);
    } else {
      this.context.commit('createExportJobFail');
    }
  }

  @Mutation
  updateProgressMutation(progress: IExportProcessing): void {
    //replace the progress
    this.refreshDashboard = false;
    const oldprogress = this.exportsProcessing.find(
      (item) => item.exportHistoryId === progress.exportHistoryId,
    );
    if (oldprogress) {
      Object.assign(oldprogress, progress);
    } else {
      this.exportsProcessing.push(progress);
    }
  }

  @Action
  async updateProgress(progress: IExportProcessing) {
    if (this.refreshDashboard) {
      //we load the explorer data tab here so the exports tab on the dashboard gets updated to processing
      // this kept firing to I've turned it off
      // await ExplorerDataModule.loadExplorerData();
    }
    this.context.commit('updateProgressMutation', progress);
  }

  @Action
  async clearCompleted() {
    this.context.commit('clearCompletedSuccess');
  }

  @Mutation
  async dismissCompleted(exportProcessingHistoryId: number) {
    this.dismissedCompleteNotifications.push(
      exportProcessingHistoryId,
    );
  }

  @Action
  async removeCompletedItem(exportProcessingHistoryId: number) {
    this.context.commit(
      'removeItemFromCompleted',
      exportProcessingHistoryId,
    );
  }

  @Action
  async cancelCurentExportJob(cancel: ICancelExport) {
    const result = await api.cancelCurentExportJob(
      cancel.exportProcessingHistoryId,
    );
  }

  @Action
  async cancelPreviousExportJobs() {
    const result = await api.cancelPreviousExportJobs();
  }

  get exportsProcessingSummary(): IExportProcessingSummary[] {
    return this.exportsProcessing.map((item) => {
      // code smell - having to create a new object to transpose information
      const retval = {
        exportHistoryId: item.exportHistoryId,
        exportCreatedUtc: item.exportCreatedUtc,
        exportIntegrationId: item.exportIntegrationId,
        exportIntegration: UserSettingsModule.integrationById(
          item.exportIntegrationId,
        ),
        exportFormatIntegration: UserSettingsModule.integrationById(
          item.exportIntegrationId,
        ),
        exportDestinationId: item.exportDestinationId,
        exportDocumentMode: item.exportDocumentMode,
        exportDestination:
          item.exportDestinationId === 1 ? 'Download' : 'Integration', //todo translate
        exportProgress: item.exportProgress,
        exportDescription: item.exportDescription,
        processingUrl: item.processingUrl,
      } as IExportProcessingSummary;
      return retval;
    });
  }

  get processingExportById() {
    return (exportProcessingHistoryId: number) => {
      return this.exportsProcessing.find(
        (e) => e.exportHistoryId === exportProcessingHistoryId,
      );
    };
  }

  get completeExportById() {
    return (exportHistoryId: number) => {
      return this.exportsCompleted.find(
        (e) => e.exportHistoryId === exportHistoryId,
      );
    };
  }

  // this applies to te selected integration in the wizard
  get selectedIntegration() {
    return UserSettingsModule.integrationById(
      this.selectedIntegrationID,
    );
  }

  get selectedExportFormatIntegration() {
    return UserSettingsModule.integrations.find(
      (i) =>
        i.IntegrationID ===
        this.selectedIntegration!.IntegrationExportSettings
          .ExportFormatIntegrationID,
    );
  }

  get integrationHasBeenSelected() {
    return this.selectedIntegrationID !== 0;
  }

  get exportsCompletedExcludingDismissed() {
    return this.exportsCompleted.filter(
      (item) =>
        !this.dismissedCompleteNotifications.includes(
          item.exportHistoryId,
        ),
    );
  }

  get exportNotificationCount() {
    return (
      this.exportsProcessing.length +
      this.exportsCompletedExcludingDismissed.length
    );
  }

  // get prechecks() {
  //   return ExplorerDataModule.getExportCriteria;
  // }
}

// store.watch(
//   (state: any) => state.exportuistate.selectedIntegrationID,
//   (value: boolean, oldValue: boolean) => {
//     console.log('selectedIntegrationID changed', value, oldValue);
//   },
//   //store.state.exportuistate.
// );

export default getModule(ExportUiStateModule);
