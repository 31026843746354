








import { Component, Vue } from 'vue-property-decorator';
import exportsprocessingnotifications from '@/components/notificationssidebar/ExportsProcessingNotifications.vue';
import exportscompletenotifications from '@/components/notificationssidebar/ExportsCompleteNotifications.vue';
import noexportsnotification from '@/components/notificationssidebar/NoExportsNotification.vue';

@Component({
  components: {
    noexportsnotification,
    exportsprocessingnotifications,
    exportscompletenotifications,
  },
})
export default class ExportNotifications extends Vue {}
