
















import { Component, Vue } from 'vue-property-decorator';
import addcollectionmodal from '@/components/modals/AddCollectionModal.vue';

@Component({
  components: {
    addcollectionmodal,
  },
})
export default class LeftSidebarNewBookCollection extends Vue {
  collectionsModalVisible = false;
  showCollectionModal() {
    this.collectionsModalVisible = true;
  }
}
