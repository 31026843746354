
























































































import { Component, Vue } from 'vue-property-decorator';
import LayoutModule from '@/store/modules/LayoutModule';
import showhidetoggle from '@/pages/explorer/components/leftsidebar/ShowHideToggle.vue';

@Component({
  components: {
    showhidetoggle,
  },
})
export default class LeftSidebarQuicklinks extends Vue {
  menuExpanded = true;
  hideLeftSidebarOnMobile() {
    LayoutModule.hideLeftSidebarOnMobile();
  }
}
