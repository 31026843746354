
















































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import { IBook } from '@/store/types/IBook';
import leftsidebarbookcollection from './LeftSidebarBookCollection.vue';
import leftsidebardeletedbooks from './LeftSidebarDeletedBooks.vue';
import leftsidebarnewbookcollection from './LeftSidebarNewBookCollection.vue';
import showhidetoggle from '@/pages/explorer/components/leftsidebar/ShowHideToggle.vue';

@Component({
  components: {
    leftsidebarbookcollection,
    leftsidebardeletedbooks,
    leftsidebarnewbookcollection,
    showhidetoggle,
  },
})
export default class LeftSidebarBooks extends Vue {
  menuExpanded = true;
  isHovered = false;
  get books() {
    return ExplorerDataModule.Books;
  }

  get BooksInCollections() {
    return ExplorerDataModule.getBooksInCollections;
  }

  get bookfilter() {
    return ExplorerUiStateModule.bookFilter;
  }

  bookFilterChanged(bookFilterText: string) {
    ExplorerUiStateModule.changeBookFilter(bookFilterText);
  }

  get filteredBooks(): IBook[] {
    return ExplorerDataModule.Books.filter((book) =>
      book.BookTitle.toLowerCase().includes(
        ExplorerUiStateModule.bookFilter.toLowerCase(),
      ),
    );
  }

  handleHover(hovered: boolean) {
    this.isHovered = hovered;
  }
}
