

















import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';

@Component
export default class NotificationsHeader extends Vue {
  dismissAll() {
    ExportUiStateModule.clearCompleted();
  }
}
