import { render, staticRenderFns } from "./LeftSidebarRecent.vue?vue&type=template&id=16a790c7&scoped=true&"
import script from "./LeftSidebarRecent.vue?vue&type=script&lang=ts&"
export * from "./LeftSidebarRecent.vue?vue&type=script&lang=ts&"
import style0 from "./LeftSidebarRecent.vue?vue&type=style&index=0&id=16a790c7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a790c7",
  null
  
)

export default component.exports