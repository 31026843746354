// x
export enum ExportError {
  NotSet = 1,
  None = 2,
  PrecheckError = 3,
  UnexpectedError = 4,
  GenerationError = 5,
  PublishError = 6,
  ExportCancelled = 7,
}

// x
export enum ExportFinalResult {
  NotSet = 1,
  AllPassed = 2,
  AllFailed = 3,
  SomeFailed = 4,
}

// x
export enum GenerationFinalResult {
  NotSet = 1,
  AllPassed = 2,
  AllFailed = 3,
  // SomeFailed = 3 Not applicable at this point in time, at the moment if one fails we fail everything
}

// x
export enum ExportGenerationError {
  NotSet = 1,
  None = 2,
  AllRecordsAlreadyExported = 3,
  NoRecordsToExport = 4,
  UnexpectedError = 5,
  PrechecksFailed = 6,
  ExportCancelled = 7,
}

// x
export enum ExportPublishError {
  NotSet = 1,
  None = 2,
  NotAuthorised = 3,
  ApiLimitReached = 4,
  UploadLimitReached = 5,
  UnexpectedError = 6,
  GenerationFailed = 7,
  PrechecksFailed = 8,
  ExportCancelled = 9,
}

// x
export enum PreCheckError {
  NotSet = 1,
  None = 2,
  CouldNotConnectToIntegration = 3,
  RequeuedExportToIntegrationStillRunning = 4,
  RequeuedSystemMaintenance = 5,
  UnexpectedError = 6,
  KindleExportWithNoKindleEmailAddress = 7,
}

// x
export enum PreCheckFinalResult {
  NotSet = 1,
  AllPassed = 2,
  AllFailed = 3,
  SomeFailed = 4,
}

// x
export enum PublishFinalResult {
  NotSet = 1,
  AllPassed = 2,
  AllFailed = 3,
  SomeFailed = 4,
}

export enum IntegrationType {
  Integration = 1,
  File = 2,
}

export enum ExportRecordFilterType {
  All = 1,
  Favorites = 2,
  Deleted = 3,
  Upload = 4,
  Book = 5,
  Tag = 6,
  Search = 7,
}

export enum IntegrationStorageType {
  File = 1,
  ProprietaryHtml = 2,
}

export enum ExportDestination {
  Download = 1,
  Integration = 2,
}

export enum ExportDocumentMode {
  OneDocument = 1,
  DocumentPerBook = 2,
  DocumentPerRecord = 3,
}

export enum ExportPublishMode {
  Append = 1,
  Overwrite = 2,
}

export enum ExportPhase {
  Generating = 1,
  Publishing = 2,
}

export enum ExportInitiation {
  User = 1,
  AutoExport = 2,
  ResumeSystemMaintenancePause = 3,
  ResumeIntegrationPause = 4,
}

// combination of processing and export final result
export enum DashboardStatus {
  Queued = 1,
  Processing = 2,
  Paused = 3,
  Failed = 4,
  Cancelled = 5,
  Completed = 6,
}

export enum CitationStyle {
  None = 0,
  Simple = 1,
  Chicago = 2,
  Apa = 3,
  Mla = 4,
}
