import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/store';

import BootstrapVue from 'bootstrap-vue';
import SpinnerPlugin from 'bootstrap-vue';
import i18n from '@/i18n';
import Vue2Filters from 'vue2-filters';
import VueMoment from 'vue-moment';
import Toasted from 'vue-toasted';
import TextHighlight from 'vue-text-highlight';
import Clipboard from 'v-clipboard';
import VuePageTransition from 'vue-page-transition';
import VueScrollTo from 'vue-scrollto';
import VueCalendarHeatmap from 'vue-calendar-heatmap';
import VueFlags from '@growthbunker/vueflags';

import './fontawesomeicons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
import VueMq from 'vue-mq';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
Vue.component('text-highlight', TextHighlight);

//Vue.use(VueIntercom, { appId: 'vgkme8rn' });
Vue.use(VueFlags, {
  iconPath: '/flags/',
});
Vue.use(Toasted);
Vue.use(VueMoment);
Vue.use(Vue2Filters);
Vue.use(Clipboard);
Vue.use(VuePageTransition);
Vue.use(VueScrollTo);
Vue.use(VueCalendarHeatmap);
Vue.use(BootstrapVue);
Vue.use(SpinnerPlugin);
// Vue.use(moment as any);

Vue.use(VueMq, {
  //changed these to match bootstrap
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity,
  },
});

Vue.config.productionTip = false;

//see https://medium.com/@brockreece/unlock-performance-tracing-in-vue-3b2c8f619cdc
// Comment out line below if you want to speed up debugging
// Vue.config.performance = process.env.NODE_ENV !== 'production';

const app = new Vue({
  mixins: [Vue2Filters.mixin],
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
// https://www.cypress.io/blog/2017/11/28/testing-vue-web-application-with-vuex-data-store-and-rest-backend/
// Cypress automatically sets window.Cypress by default
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  // only available during E2E tests
  window.app = app;
}
