




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { IBook } from '@/store/types/IBook';
import { IModalRequest } from '@/store/types/IModalRequest';
import leftsibebarshowall from '@/pages/explorer/components/leftsidebar/LeftSidebarShowAll.vue';

@Component({
  components: {
    leftsibebarshowall,
  },
})
export default class LeftSidebarBookCollectionBooks extends Vue {
  @Prop() filteredBooks!: IBook[];
  @Prop() bookFilterApplied!: boolean;
  hoveredBookId = -1;
  showAll = false;
  pageSize = 25;
  get pagedItems() {
    return this.showAll
      ? this.filteredBooks
      : this.filteredBooks.slice(0, this.pageSize);
  }

  handleHoverBook(BookId: number) {
    this.hoveredBookId = BookId;
  }

  isHovered(BookId: number) {
    return this.hoveredBookId === BookId;
  }

  deleteBook(bookid: number) {
    ExplorerDataModule.deleteBook({
      id: bookid,
      modal: this.$bvModal,
    } as IModalRequest);
  }
}
