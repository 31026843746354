










import { Component, Vue } from 'vue-property-decorator';
import leftsidebarbooks from './LeftSidebarBooks.vue';
import leftsidebarimports from './LeftSidebarImports.vue';
import leftsidebarquicklinks from './LeftSidebarQuicklinks.vue';
import leftsidebartags from './LeftSidebarTags.vue';
import leftsidebarrecent from './LeftSidebarRecent.vue';

@Component({
  components: {
    leftsidebarrecent,
    leftsidebarbooks,
    leftsidebarimports,
    leftsidebarquicklinks,
    leftsidebartags,
  },
})
export default class Leftsidebarcontent extends Vue {}
