var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exportcompletenotifications"},_vm._l((_vm.exportsCompleted),function(exp){return _c('exportnotification',{key:exp.exportHistoryId,attrs:{"title":exp.exportFailed
        ? _vm.$t('TL_SHARED_EXPORT_FAILED')
        : _vm.$t('TL_SHARED_EXPORT_COMPLETE'),"icon":exp.exportFailed ? 'times-circle' : 'check-circle',"timecreated":new Date(),"dismissable":"true"},on:{"dismiss":function($event){return _vm.dismissNotification(exp.exportHistoryId)}}},[_c('b-col',[_c('p',[_vm._v(_vm._s(exp.exportResultDescription))]),_vm._v(" "),(!exp.exportFailed)?_c('div',[(
            exp.integration.integrationTypeID == 1 &&
            exp.integration.integrationViewOnlineUrl
          )?_c('b-button',{attrs:{"variant":"primary","size":"sm","href":exp.integration.integrationViewOnlineUrl,"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.$t('TL_EXPORT_COMPLETE_VIEW_IN', {
              integrationFriendlyName:
                exp.integration.integrationFriendlyName,
            }))+"\n        ")]):_vm._e(),_vm._v(" "),(exp.integration.integrationTypeID == 2)?_c('b-button',{attrs:{"variant":"primary","size":"sm","href":exp.publishResult.downloadResults.downloadFilePath,"download":""}},[_vm._v(_vm._s(_vm.$t('TL_SHARED_DOWNLOAD_FILE')))]):_vm._e(),_vm._v(" "),_c('b-button',{staticClass:"mt-2",attrs:{"to":exp.processingUrl + '/complete',"variant":"success","size":"sm"}},[_vm._v(_vm._s(_vm.$t('TL_EXPORT_COMPLETE_VIEW_SUMMARY')))])],1):_vm._e(),_vm._v(" "),(exp.exportFailed)?_c('div',[(exp.errorTypeString === 'ExportCancelled')?_c('div',[_vm._v("\n          "+_vm._s(_vm.$t('TL_SHARED_EXPORT_CANCELLED'))+"\n        ")]):_vm._e(),_vm._v(" "),(exp.errorTypeString === 'PrecheckError')?_c('div',[(
              exp.precheckResult.errorTypeString ===
              'CouldNotConnectToIntegration'
            )?_c('b-button',{attrs:{"variant":"primary","size":"sm","to":"/settings/integrations/"}},[_vm._v("\n            "+_vm._s(_vm.$t('TL_EXPORT_COMPLETE_GOTO_INTEGRATIONS_PAGE'))+"\n          ")]):_vm._e(),_vm._v(" "),(
              exp.precheckResult.errorTypeString ===
              'RequeuedExportToIntegrationStillRunning'
            )?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.cancelExports()}}},[_vm._v("\n            "+_vm._s(_vm.$t('TL_SHARED_CANCEL_RUNNING_EXPORTS'))+"\n          ")]):_vm._e(),_vm._v(" "),(
              exp.precheckResult.errorTypeString ===
              'KindleExportWithNoKindleEmailAddress'
            )?_c('b-button',{attrs:{"variant":"danger","size":"sm","to":"/settings/integrations/amazon"}},[_vm._v("\n            "+_vm._s(_vm.$t(
                'TL_EXPORT_COMPLETE_CONFIGURE_KINDLE EMAIL_ADDRESS'
              ))+"\n          ")]):_vm._e()],1):_vm._e()]):_vm._e()])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }