














import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HelpNavItem extends Vue {
  showHelpCrunch() {
    // @ts-ignore
    HelpCrunch('openChat');
  }
}
