import {
  VuexModule,
  Module,
  getModule,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { IUserProfile } from '@/store/types/IUserProfile';
import * as api from '@/store/api';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import router from '@/router';
import { ILogin } from '../types/ILogin';
import { ILoginResult } from '../types/ILoginResult';
import * as jwtHelper from '@/services/jwtHelper';
import i18n from '@/i18n';
import BillingModule from './BillingModule';
import ExplorerUiStateModule from './ExplorerUiStateModule';
import ExportUiStateModule from './ExportUiStateModule';
import FileUploadModule from './FileUploadModule';
import ImportUiStateModule from './ImportUiStateModule';
import UserAccountModule from './UserAccountModule';
import FeatureFlagsModule from './FeatureFlagsModule';

//import * as intercom from '@/services/intercomservice';
import * as Sentry from '@sentry/vue';

const storageUser = JSON.parse(
  localStorage.getItem('user') as string,
) as ILoginResult | null;

@Module({ dynamic: true, store, name: 'auth' })
class AuthModule extends VuexModule {
  isInitialised = false;
  isAuthenticated = false;
  idToken = '';
  idTokenExpiresAt = new Date();
  refreshToken = '';
  //refreshTokenExpiresAt //not possible to get this but I think it is one year
  profile: IUserProfile | null = null;
  loginProcessing = false;
  loginError = '';

  @Mutation
  loadingUserFromStorage(storageUser: ILoginResult) {
    this.idToken = storageUser.idToken;
    this.idTokenExpiresAt = jwtHelper.getTokenExpiryDate(
      storageUser.idToken,
    );
    this.refreshToken = storageUser.refreshToken;
    this.profile = storageUser.profile;
    i18n.locale = storageUser.profile.PreferredLanguage;
    this.isInitialised = true;
    this.isAuthenticated = true;
  }

  @Action
  load() {
    if (!this.isInitialised && storageUser) {
      this.context.commit('loadingUserFromStorage', storageUser);
      this.context.dispatch('updateIntercom', storageUser.profile);
      FeatureFlagsModule.setFeatureFlags(storageUser.profile);
    }
  }

  @Action
  async refreshTokenIfRequired() {
    try {
      //Only attempt to refresh token if the user is logged in and
      //they have a refresh token
      if (this.idToken) {
        if (jwtHelper.jwtExpired(this.idToken)) {
          if (this.refreshToken) {
            const result = await api.exchangeRefreshToken(
              this.refreshToken,
            );

            this.context.commit('loginSuccess', result);
          } else {
            //login expired with no refresh token
            await this.context.dispatch('showLogin');
          }
        }
      }
    } catch (error) {
      console.error(error);
      this.context.commit('loginFailed', error as ILoginResult);
      this.context.commit('clearUser');
    }
  }

  // @Mutation
  // updateUsername(username: string) {
  //   this.profile!.UserName = username;
  // }

  // @Mutation
  // updateEmail(email: string) {
  //   this.profile!.Email = email;
  // }

  // @Mutation
  // updatePreferredLanguage(preferredLanguage: string) {
  //   i18n.locale = preferredLanguage;
  // }

  @Action
  updateIntercom(profile: IUserProfile) {
    console.log('updating intercom');
    // also do sentry here
    Sentry.setUser({
      email: profile.Email,
      username: profile.UserName,
      id: profile.UserGuid,
    });

    // intercom.startIntercomForUsers({
    //   app_id: 'vgkme8rn',
    //   name: profile.UserName, // Full name
    //   email: profile.Email, // Email address
    //   created_at: new Date(profile.CreateDate).getTime() / 1000, // Signup date as a Unix timestamp
    // });

    // https://docs.helpcrunch.com/developers/methods
    // @ts-ignore
    HelpCrunch('userAuth', {
      email: profile.Email,
      name: profile.UserName,
      user_id: profile.UserGuid,
    });
  }

  @Mutation
  resetLoginPage() {
    this.loginProcessing = false;
    this.loginError = '';
  }

  @Mutation
  setLoginProcessing() {
    this.loginProcessing = true;
    this.loginError = '';
  }

  @Mutation
  loginSuccess(login: ILoginResult) {
    localStorage.setItem('user', JSON.stringify(login));
    this.isInitialised = true;
    this.idToken = login.idToken;
    this.idTokenExpiresAt = jwtHelper.getTokenExpiryDate(
      login.idToken,
    );
    this.refreshToken = login.refreshToken;
    this.isAuthenticated = true;
    this.loginProcessing = false;
    this.loginError = '';
    this.profile = login.profile;
    i18n.locale = login.profile.PreferredLanguage;
  }

  @Mutation
  loginFailed(login: ILoginResult) {
    localStorage.removeItem('user');
    this.loginProcessing = false;
    this.loginError = login.errorMessage;
  }

  @Mutation
  refreshUserSuccess(profile: IUserProfile) {
    this.profile = profile;
    i18n.locale = profile.PreferredLanguage;
    //also update the user in storage
    const storageUser = JSON.parse(
      localStorage.getItem('user') as string,
    ) as ILoginResult | null;
    storageUser!.profile = profile;
    localStorage.setItem('user', JSON.stringify(storageUser));
  }

  // Used after we change username/email address to refresh the user details
  // And after Stripe Checkout
  @Action
  async refreshUser() {
    const profile = await api.refreshUserProfile();
    this.context.commit('refreshUserSuccess', profile);
  }

  @Mutation
  clearUser() {
    //don't ever clear the errorMessage in this function
    localStorage.removeItem('user');
    this.isInitialised = false;
    this.idToken = '';
    this.isAuthenticated = false;
    this.refreshToken = '';
    this.idTokenExpiresAt = new Date();
    this.profile = null;
  }

  @Action
  async showLogin() {
    router.push('/login');
  }

  @Action
  async login(login: ILogin) {
    await this.context.dispatch('loginWithoutRedirect', login);
    if (this.isAuthenticated) {
      router.push('/');
    }
  }

  @Action
  async loginWithoutRedirect(login: ILogin) {
    try {
      this.context.commit('clearUser');
      this.context.commit('setLoginProcessing');
      const loginResult = await api.login(login);
      this.context.commit('loginSuccess', loginResult);
      this.context.dispatch('updateIntercom', loginResult.profile);
    } catch (error) {
      this.context.commit('loginFailed', error as ILoginResult);
      this.context.commit('clearUser');
    }
  }

  @Action
  async logout() {
    this.context.commit('clearUser');
    this.context.dispatch('logoutSharedActions');
    router.push('/login');
  }

  @Action
  async logoutWithRedirect(redirect: string) {
    this.context.commit('clearUser');
    this.context.dispatch('logoutSharedActions');
    router.push(redirect);
  }

  @Action logoutSharedActions() {
    BillingModule.clear();
    ExplorerDataModule.clear();
    ExplorerUiStateModule.clear();
    ExportUiStateModule.clear();
    FileUploadModule.clear();
    ImportUiStateModule.clear();
    UserAccountModule.clear();
    UserSettingsModule.clear();
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  get isLoaded() {
    return this.isAuthenticated && this.profile !== null;
  }
}
export default getModule(AuthModule);
