

























































import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component
export default class AddCollectionModal extends Vue {
  @PropSync('visible') isVisible!: boolean;
  collectionName = '';
  selectedBooks: number[] = [];

  reset() {
    this.collectionName = '';
    this.selectedBooks = [];
  }

  get validateCollectionName() {
    if (this.collectionName) {
      return (
        this.collectionName!.length > 0 &&
        this.collectionName!.length <= 250
      );
    } else {
      return null;
    }
  }

  get books() {
    return ExplorerDataModule.nonDeletedBooks;
  }

  addBookCollection() {
    //todo need to check if this collection name is already used
    if (this.validateCollectionName) {
      ExplorerDataModule.addBookCollection({
        BookCollectionName: this.collectionName,
        BookIds: this.selectedBooks,
        BookCollectionID: -1,
      });
    }
  }
}
