import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import * as api from '@/store/api';
import { INoteLinkingSettings } from '@/store/types/INoteLinkingSettings';
import { IEmailSubscriptions } from '@/store/types/IEmailSubscriptions';
import { IAmazonSettings } from '@/store/types/IAmazonSettings';
import { IIntegration } from '@/store/types/IIntegrations';
import { IBulkGetSettingsResponse } from '@/store/types/responses/IBulkGetSettingsResponse';
import Vue from 'vue';
import { IIntegrationExportSettings } from '@/store/types/IIntegrationExportSettings';
import { ILanguage } from '../types/ILanguage';
import ExportUiStateModule from './ExportUiStateModule';
import { IModalRequestIntegrationExportSettings } from '../types/IModalRequestSaveIntegrationExportSettings';
import i18n from '@/i18n';
import { IResetExportedRecords } from '../types/IResetExportedRecords';
import router from '@/router';
@Module({ dynamic: true, store, name: 'usersettings' })
class UserSettingsModule extends VuexModule {
  justConnectedToIntegrationId = 0;
  isLoading = true;
  isInitialised = false;
  // Settings
  notelinkingsettings: INoteLinkingSettings | null = null;
  linkednotecount = 0;
  emailsubscriptions: IEmailSubscriptions | null = null;
  amazonsettings: IAmazonSettings | null = null;
  integrations: IIntegration[] = [];

  language: ILanguage = { code: 'en-US', svg: 'us', name: 'English' };

  languages: ILanguage[] = [
    { code: 'en-US', svg: 'us', name: 'English' },
    { code: 'de', svg: 'de', name: 'Deutsch' },
    { code: 'es-ES', svg: 'es', name: 'Español' },
    { code: 'fr', svg: 'fr', name: 'Français' },
    { code: 'it', svg: 'it', name: 'Italiano' },
    { code: 'ja', svg: 'jp', name: '日本語' },
    { code: 'pl', svg: 'pl', name: 'Polski' },
    { code: 'pt-BR', svg: 'br', name: 'Português' },
    { code: 'ru', svg: 'ru', name: 'Русский' },
    { code: 'zh-CN', svg: 'cn', name: '中文' },
  ];

  @Mutation
  clear() {
    this.isLoading = true;
    this.isInitialised = false;
    // Settings
    this.notelinkingsettings = null;
    this.linkednotecount = 0;
    this.emailsubscriptions = null;
    this.amazonsettings = null;
    this.integrations = [];
  }

  @Mutation
  setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  @Mutation
  kindleEmailAddressUpdated(KindleEmailAddress: string): void {
    this.amazonsettings!.KindleEmailAddress = KindleEmailAddress;
  }

  @Mutation
  settingsDataLoaded(response: IBulkGetSettingsResponse) {
    this.isInitialised = true;
    this.notelinkingsettings = response.NoteLinkingSettings;
    this.linkednotecount = response.LinkedNoteCount;
    this.emailsubscriptions = response.EmailSubscriptions;
    this.amazonsettings = response.AmazonSettings;
    this.integrations = response.Integrations;
  }

  @Action
  async loadSettingsData() {
    // this.context.commit('setIsLoading', true);
    const response = await api.loadSettingsData();
    this.context.commit('settingsDataLoaded', response);
    // this.context.commit('setIsLoading', false);
  }

  @Mutation
  revokedIntegration(integrationID: number) {
    this.integrations.map((item) => {
      if (item.IntegrationID === integrationID) {
        item.Connected = false;
      } else {
        return item;
      }
    });
  }

  @Mutation
  updateAutoSync(integrationID: number) {
    this.integrations.map((item) => {
      if (item.IntegrationID === integrationID) {
        item.IntegrationExportSettings.AutoExport = !item
          .IntegrationExportSettings.AutoExport;
      } else {
        return item;
      }
    });
  }
  @Action
  async toggleAutoSync(integrationID: number) {
    this.context.commit('updateAutoSync', integrationID);
    await api.toggleAutoSync(integrationID);
  }

  @Action
  async authenticateIntegration(integrationId: number) {
    localStorage.setItem(
      'authenticateIntegrationCallingUrl',
      router.currentRoute.path,
    );
    const authResult = await api.authenticateIntegration(
      integrationId,
    );
    window.location.href = authResult!.AuthorizeUrl;
  }

  @Mutation
  integrationConnectedComplete(integrationID: number) {
    this.justConnectedToIntegrationId = integrationID;
  }

  @Action
  async integrationConnected(integrationId: number) {
    this.context.commit(
      'integrationConnectedComplete',
      integrationId,
    );
  }

  @Action
  async updateAmazonKindleEmailAddress(KindleEmailAddress: string) {
    this.context.commit(
      'kindleEmailAddressUpdated',
      KindleEmailAddress,
    );
    await api.updateAmazonKindleEmailAddress({
      KindleEmailAddress,
    } as IAmazonSettings);
    // todo translate message
    Vue.toasted.success(
      i18n.t('TL_SHARED_KINDLE_EMAIL_ADDRESS_UPDATED').toString(),
      {
        duration: 2000,
        position: 'bottom-center',
      },
    );
  }

  @Action
  async revokeIntegration(integrationId: number) {
    this.context.commit('revokedIntegration', integrationId);
    await api.revokeIntegration(integrationId);
    // todo translate message
    Vue.toasted.success(
      i18n.t('TL_SHARED_INTEGRATION_DISCONNECTED').toString(),
      {
        duration: 2000,
        position: 'bottom-center',
      },
    );
  }

  @Mutation
  resetAllExportedRecordsForIntegrationComplete(
    integrationID: number,
  ) {
    this.integrations.map((item) => {
      if (item.IntegrationID === integrationID) {
        item.ExportedRecordCount = 0;
        item.ExportFileTypeStats = [];
      } else {
        return item;
      }
    });
  }

  @Action
  async resetAllExportedRecordsForIntegration(integrationId: number) {
    await api.resetAllExportedRecordsForIntegration(integrationId);
    this.context.commit(
      'resetAllExportedRecordsForIntegrationComplete',
      integrationId,
    );
    await ExportUiStateModule.exportPrechecks();
    Vue.toasted.success(
      i18n.t('TL_SHARED_RESET_SUCCESSFUL').toString(),
      {
        duration: 2000,
        position: 'bottom-center',
      },
    );
  }

  @Mutation
  resetExportedRecordsForFileStorageIntegrationComplete(
    resetExportedRecords: IResetExportedRecords,
  ) {
    this.integrations.map((item) => {
      if (item.IntegrationID === resetExportedRecords.integrationId) {
        item.ExportFileTypeStats.map((fileType) => {
          if (
            fileType.ExportFormatIntegrationID ===
            resetExportedRecords.exportFormatIntegrationid
          ) {
            item.ExportedRecordCount =
              item.ExportedRecordCount - fileType.ExportedRecordCount;
            fileType.ExportedRecordCount = 0;
          }
        });
      } else {
        return item;
      }
    });
  }

  @Action
  async resetExportedRecordsForFileStorageIntegration(
    resetExportedRecords: IResetExportedRecords,
  ) {
    console.log('resetExportedRecords', resetExportedRecords);
    await api.resetExportedRecordsForFileStorageIntegration(
      resetExportedRecords,
    );
    this.context.commit(
      'resetExportedRecordsForFileStorageIntegrationComplete',
      resetExportedRecords,
    );
    await ExportUiStateModule.exportPrechecks();
    Vue.toasted.success(
      i18n.t('TL_SHARED_RESET_SUCCESSFUL').toString(),
      {
        duration: 2000,
        position: 'bottom-center',
      },
    );
  }

  @Mutation
  saveIntegrationExportSettingsSuccess(
    settings: IIntegrationExportSettings,
  ) {
    const integration = this.integrations.find(
      (i) => i.IntegrationID === settings.IntegrationID,
    );
    Object.assign(
      integration,
      (integration!.IntegrationExportSettings = settings),
    );
  }

  @Action
  async saveIntegrationExportSettings(
    modelSettings: IModalRequestIntegrationExportSettings,
  ) {
    const { settingName, settingValue, modal } = modelSettings;

    const clonedSettings = Object.assign(
      {},
      ExportUiStateModule.selectedIntegration!
        .IntegrationExportSettings,
    ) as any;
    clonedSettings[settingName] = settingValue;
    console.log(
      'Saving Integration Export Settings',
      `${settingName}=${settingValue}`,
    );
    if (modelSettings.showResetPrompt && this.showResetPrompt) {
      modal
        .msgBoxConfirm(
          i18n
            .t(
              'TL_MSGBOX_YOU_HAVE_CHANGED_DOCUMENT_FORMATTING_OPTIONS',
            )
            .toString(),
          {
            title: i18n.t('TL_MSGBOX_REEXPORT').toString(),
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: i18n.t('TL_DIALOG_YES').toString(),
            cancelTitle: i18n.t('TL_DIALOG_NO').toString(),
            footerClass: 'p-2',
            hideHeaderClose: true,
            centered: true,
          },
        )
        .then((value) => {
          if (value) {
            if (
              ExportUiStateModule.selectedIntegration
                ?.IsIntegrationWhichSupportsStoringFiles
            ) {
              this.context.dispatch(
                'resetExportedRecordsForFileStorageIntegration',
                {
                  integrationId: ExportUiStateModule.selectedIntegration!
                    .IntegrationExportSettings.IntegrationID,
                  exportFormatIntegrationid: ExportUiStateModule.selectedIntegration!
                    .IntegrationExportSettings
                    .ExportFormatIntegrationID,
                },
              );
            } else {
              this.context.dispatch(
                'resetAllExportedRecordsForIntegration',
                ExportUiStateModule.selectedIntegration!
                  .IntegrationExportSettings.IntegrationID,
              );
            }
            //this.context.commit('setResetExportedRecords', true);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    }

    await api.saveIntegrationExportSettings(clonedSettings!);
    this.context.commit(
      'saveIntegrationExportSettingsSuccess',
      clonedSettings,
    );
    await ExportUiStateModule.exportPrechecks();
  }

  //If we have already exported records to this integration
  get showResetPrompt() {
    return (
      this.exportedRecordCount(
        ExportUiStateModule.selectedIntegration!.IntegrationID,
        ExportUiStateModule.selectedExportFormatIntegration!
          .IntegrationID,
      ) > 0
    );
  }

  get integrationJustConnected() {
    return this.justConnectedToIntegrationId !== 0;
  }

  get justConnectedToIntegration() {
    return this.integrations.find(
      (i) =>
        i.IntegrationID === Number(this.justConnectedToIntegrationId),
    );
  }

  get connectedIntegrations(): IIntegration[] {
    return this.integrations.filter(
      (i) => i.Enabled && i.Connected && i.IntegrationTypeID === 1,
    );
  }

  get unconnectedIntegrations(): IIntegration[] {
    return this.integrations.filter(
      (i) => i.Enabled && !i.Connected && i.IntegrationTypeID === 1,
    );
  }

  get integrationById() {
    return (integrationId: number) => {
      return this.integrations.find(
        (i) => i.IntegrationID === integrationId,
      );
    };
  }

  get languageByCode() {
    return (code: string) => {
      return this.languages.find(
        (l) => l.code.toLowerCase() === code.toLowerCase(),
      );
    };
  }

  get exportedRecordCount() {
    return (
      integrationId: number,
      exportFormatIntegrationId: number,
    ) => {
      const integration = this.integrations.find(
        (i) => i.IntegrationID === integrationId,
      );
      if (integration!.ExportFileTypeStats.length > 0) {
        const exportformatIntegration = integration!.ExportFileTypeStats.find(
          (i) =>
            i.ExportFormatIntegrationID === exportFormatIntegrationId,
        );
        if (exportformatIntegration) {
          return exportformatIntegration.ExportedRecordCount;
        } else {
          return 0;
        }
      }
      // integrations which don't export by file, i.e. Evernote/OneNote
      return integration!.ExportedRecordCount;
    };
  }
}

export default getModule(UserSettingsModule);
