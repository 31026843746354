import { faLessThan } from '@fortawesome/pro-duotone-svg-icons';
import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

//https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  // console.log('locales', locales);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// console.log(navigator.language);
// console.log(loadLocaleMessages());

export default new VueI18n({
  locale: mapNavigatorLanguageToMessages(
    navigator.language.split('-')[0],
  ), // process.env.VUE_APP_I18N_LOCALE || 'en-US',
  silentFallbackWarn: false,
  silentTranslationWarn: false,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  messages: loadLocaleMessages(),
});

//maps language in the navigator to same format as what we use in loadLocaleMessages()
//whenever we add a new language we need to check this
//Best way to check this is on the login page when we are signed out
//as this will use the navigator.language and not what we have stored in
//the database
function mapNavigatorLanguageToMessages(navLang: string) {
  switch (navLang) {
    case 'en':
      return 'en-US';
    case 'es':
      return 'es-ES';
    case 'pt':
      return 'pt-BR';
    case 'zh':
      return 'zh-CN';
    default:
      return navLang;
  }
}

// export function getBrowserLocale(options = {}) {
//   const defaultOptions = { countryCodeOnly: false };

//   const opt = { ...defaultOptions, ...options };

//   let navigatorLocale =
//     navigator.languages !== undefined
//       ? navigator.languages[0]
//       : navigator.language;

//   if (!navigatorLocale) {
//     navigatorLocale = 'en-US';
//   }

//   const trimmedLocale = opt.countryCodeOnly
//     ? navigatorLocale.trim().split(/-|_/)[0]
//     : navigatorLocale.trim();

//   return trimmedLocale;
// }

// We remove empty keys so that the fallback language gets invoked
// see https://github.com/kazupon/vue-i18n/issues/563

//WARNING DON"T USE THIS FALLBACK CODE IT BREAKS NAMED LOCALISATION
// Vue.prototype.$t = function (
//   key: string,
//   locale: string,
//   values: any[],
// ) {
//   const result = this.$i18n.t(key, locale, values);
//   if (result === '') {
//     // if empty string use the fallback languages
//     console.log(`${key} not translated for ${locale}`);
//     return this.$i18n.t(
//       key,
//       process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
//       values,
//     );
//   }
//   return result;
// };

// Vue.prototype.$tc = function (
//   key: string,
//   choice: string,
//   locale: string,
//   values: any[],
// ) {
//   const result = this.$i18n.tc(key, choice, locale, values);
//   if (result === '') {
//     return key;
//   }
//   return result;
// };

// Vue.prototype.$t = function (...args: any[]) {
//   const result = this.$i18n.t.apply(this.$i18n, args);
//   if (result === '') {
//     return args[0];
//   }
//   return result;
// };

// Vue.prototype.$tc = function (...args: any[]) {
//   const result = this.$i18n.tc.apply(this.$i18n, args);
//   if (result === '') {
//     return args[0];
//   }
//   return result;
// };
