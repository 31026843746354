






























































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import { IBook } from '@/store/types/IBook';
import leftsibebarshowall from '@/pages/explorer/components/leftsidebar/LeftSidebarShowAll.vue';

@Component({
  components: {
    leftsibebarshowall,
  },
})
export default class LeftSidebarDeletedBooks extends Vue {
  mouseOverBookID = -1;
  menuExpanded = false;
  showAll = false;
  pageSize = 10;

  get pagedItems() {
    return this.showAll
      ? this.filteredBooks(this.deletedbooks)
      : this.filteredBooks(this.deletedbooks).slice(0, this.pageSize);
  }

  get foldericon() {
    return this.menuExpanded
      ? ['far', 'folder-open']
      : ['far', 'folder'];
  }

  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }

  mounted() {
    this.menuExpanded = this.shouldMenuBeExpanded();
  }

  toggleMenuExpanded() {
    this.menuExpanded = !this.menuExpanded;
  }

  shouldMenuBeExpanded() {
    // Book Collection Menu should be expanded if:
    // A book search filter is applied
    // It's books contains the active book
    // Book Collection Menu should NOT be expanded if:
    // If a book folder is not selected

    if (this.bookfilter.length > 0) {
      return true;
    }

    if (this.selectedfolder.FolderName !== 'books') {
      return false;
    }

    if (this.deletedbooks != null) {
      return (
        this.deletedbooks.filter(
          (book) =>
            book.BookId === Number(this.selectedfolder.SelectedId),
        ).length > 0
      );
    } else {
      return false;
    }
  }

  get bookfilter() {
    return ExplorerUiStateModule.bookFilter;
  }

  restoreBook(bookid: number) {
    ExplorerDataModule.restoreBook({
      id: bookid,
      modal: this.$bvModal,
    });
  }

  get deletedbooks() {
    return ExplorerDataModule.Books.filter(
      (book) => book.Deleted === true,
    );
  }

  displayBookCollection(books: IBook[]) {
    // Don't display the book collection if:
    // The book filter is applied and this collection does not contain any books matching the filter
    // It's the uncategorised collection and it has no books
    if (
      this.bookfilter.length > 0 &&
      books.filter((book) =>
        book.BookTitle.toLowerCase().includes(
          this.bookfilter.toLowerCase(),
        ),
      ).length === 0
    ) {
      return false;
    }
    return !(books.length === 0);
  }

  filteredBooks(books: IBook[]): IBook[] {
    if (this.bookfilter.length === 0) {
      return books;
    } else {
      return books.filter((book) =>
        book.BookTitle.toLowerCase().includes(
          this.bookfilter.toLowerCase(),
        ),
      );
    }
  }

  bookFilterApplied() {
    return this.bookfilter.length > 0;
  }

  mouseEnterBook(BookId: number) {
    this.mouseOverBookID = BookId;
  }

  mouseLeaveBook() {
    this.mouseOverBookID = -1;
  }
}
