



























import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import exportnotification from '@/components/notificationssidebar/ExportNotification.vue';

@Component({
  components: {
    exportnotification,
  },
})
export default class ExportProcessingNotifications extends Vue {
  get exportsProcessing() {
    return ExportUiStateModule.exportsProcessingSummary;
  }
}
