
















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import LayoutModule from '@/store/modules/LayoutModule';
import logo from '@/components/logos/Logo.vue';

@Component({
  components: {
    logo,
  },
})
export default class UnAuthLayout extends Vue {}
